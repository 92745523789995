<template>
  <button @click="refreshAppData">
    <i class="fas fa-sync mr-2 d-none d-lg-inline" aria-hidden="true"></i>
    {{ $t('general.refresh') }}
  </button>
</template>

<script setup>
import { useRefreshEvent } from '@/composables/events/refreshEvent'
import axios from '@/axios'

const refreshEvent = useRefreshEvent()

const emit = defineEmits(['click'])

function refreshAppData() {
  axios
    .get('/api/checkAge')
    .then(({ data }) => {
      refreshEvent.emit()
    })
    .catch((e) => {
      // ignore
    })

  emit('click')
}
</script>
