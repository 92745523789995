import { defineStore } from 'pinia'
import { ref, onMounted, computed } from 'vue'
import axios from '@/axios'

export const useMarketStore = defineStore('market', (autoFetch = true) => {
  const markets = ref([])

  onMounted(() => {
    if (autoFetch) {
      fetchMarkets()
    }
  })

  async function fetchMarkets() {
    if (markets.value.length > 0) {
      return
    }

    const response = await axios.get('/api/market')

    markets.value = response.data.data

    return markets.value
  }

  const futureMarkets = computed(() => {
    return markets.value.filter((market) => {
      return market.markettype === 'future'
    })
  })

  const spotMarkets = computed(() => {
    return markets.value.filter((market) => {
      return market.markettype === 'spot'
    })
  })

  const fuelMarkets = computed(() => {
    return markets.value.filter((market) => {
      return market.markettype === 'fuel'
    })
  })

  return {
    markets,
    fetchMarkets,
    futureMarkets,
    spotMarkets,
    fuelMarkets
  }
})
