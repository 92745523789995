import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'
import axios from '@/axios'

export const useProductStore = defineStore('product', (autoFetch = true) => {
  const products = ref([])

  onMounted(() => {
    if (autoFetch) {
      fetchProducts()
    }
  })

  async function fetchProducts() {
    if (products.value.length > 0) {
      return
    }

    const response = await axios.get('/api/product', {
      params: {
        order: ['datekey:asc', 'period:desc'],
      },
    })

    products.value = response.data.data
    return products.value
  }

  return { products, fetchProducts }
})
