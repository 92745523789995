<template>
  <div>
    <table
      class="table table-striped"
      :class="{ 'sticky-head': stickyHead, tableClass }"
    >
      <slot name="head"></slot>
      <tbody v-if="loading || error">
        <tr v-for="index in expectedRows" :key="index">
          <td :colspan="columns" class="text-center">
            <i
              v-if="index === 1"
              class="fas"
              :class="{
                'fa-spin fa-spinner': loading,
                'fa-exclamation-triangle mr-2': error,
              }"
              aria-hidden="true"
            ></i>
            <template v-if="error && index === 1">
              {{ $t('general.data_error') }}
            </template>
          </td>
        </tr>
      </tbody>
      <transition-group
        v-if="!loading && !error"
        name="fade"
        tag="tbody"
        style="border-top: none"
      >
        <template v-for="(item, index) in items" :key="index">
          <tr v-show="item.isShown !== false">
            <slot name="row" v-bind:item="item" v-bind:index="index"></slot>
          </tr>
        </template>
      </transition-group>
      <transition name="fade" mode="out-in">
        <tbody style="border-top: none" v-if="!loading && !error">
          <slot></slot>
        </tbody>
      </transition>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      default: 6,
    },
    expectedRows: {
      required: true,
      type: Number,
    },
    loading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    error: {
      default: false,
      type: Boolean,
    },
    items: {
      type: [Object, Array],
      default: () => {},
    },
    tableClass: {
      type: String,
      default: '',
    },
    stickyHead: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
