<template>
  <div v-if="visible">
    <div class="pricingTitle">
      <h3>
        <utility-icon :util="util"></utility-icon>
        <span>
          {{ $t(`general.market.spot`) }}
          {{ countryStore.country.code.toUpperCase() }}
          {{ $t(`general.utilitytype.${util}`) }}
        </span>
      </h3>
    </div>

    <loadable-table
      :loading="priceLoader"
      :error="error"
      :expected-rows="2"
      :table-class="tableClass"
    >
      <template #head>
        <thead slot="head">
          <tr>
            <th scope="col">{{ $t('component.spottable.product') }}</th>
            <th
              scope="col"
              v-if="convertsPrice"
              v-tooltip:top.html="baseTooltip"
            >
              {{ $t('component.spottable.basem3') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th scope="col" v-else v-tooltip:top.html="baseTooltip">
              {{ $t('component.spottable.base') }}
              <i class="fas fa-info-circle"></i>
            </th>

            <template v-if="util === 'e'">
              <th scope="col">{{ $t('component.spottable.peak') }}</th>
              <th scope="col">{{ $t('component.spottable.offpeak') }}</th>
            </template>
            <template v-else-if="util === 'g'">
              <th></th>
            </template>
            <th
              scope="col"
              v-if="yearData && yearData.date"
              class="text-center"
            >
              {{ yearDataDate }}
            </th>
          </tr>
        </thead>
      </template>

      <tr>
        <td>{{ currentYear }}</td>
        <td>
          {{
            convertsPrice
              ? formatter.volume(yearData ? yearData.base : 0)
              : formatter.decimal(yearData ? yearData.base : 0)
          }}
        </td>
        <template v-if="util === 'e'">
          <td>
            {{
              convertsPrice
                ? formatter.volume(yearData ? yearData.peak : 0)
                : formatter.decimal(yearData ? yearData.peak : 0)
            }}
          </td>
          <td v-if="util === 'e'">
            {{
              convertsPrice
                ? formatter.volume(yearData ? yearData.offpeak : 0)
                : formatter.decimal(yearData ? yearData.offpeak : 0)
            }}
          </td>
        </template>
        <template v-else-if="util === 'g'">
          <td></td>
        </template>
        <td class="text-center">
          <indicator-icon v-if="yearData" :preset="yearData.baseposition" />
        </td>
        <td v-if="yearData" class="text-right">
          <router-link
            :to="{
              name: 'SpotPriceMatrix',
              params: {
                util,
              },
            }"
          >
            <i class="fas fa-table" aria-hidden="true"></i>
          </router-link>
        </td>
      </tr>

      <tr v-if="monthData">
        <td>{{ formatter.name(monthDataDate) }}</td>
        <td>
          {{
            convertsPrice
              ? formatter.volume(monthData ? monthData.base : 0)
              : formatter.decimal(monthData ? monthData.base : 0)
          }}
        </td>
        <template v-if="util === 'e'">
          <td>
            {{
              convertsPrice
                ? formatter.volume(monthData ? monthData.peak : 0)
                : formatter.decimal(monthData ? monthData.peak : 0)
            }}
          </td>
          <td>
            {{
              convertsPrice
                ? formatter.volume(monthData ? monthData.offpeak : 0)
                : formatter.decimal(monthData ? monthData.offpeak : 0)
            }}
          </td>
        </template>
        <template v-else-if="util === 'g'">
          <td></td>
        </template>
        <td class="text-center">
          <indicator-icon v-if="monthData" :preset="monthData.baseposition" />
        </td>
        <td v-if="monthData" class="text-right">
          <router-link
            :to="{
              name: 'SpotPriceMatrix',
              params: {
                util,
              },
            }"
          >
            <i class="fas fa-table" aria-hidden="true"></i>
          </router-link>
        </td>
      </tr>
    </loadable-table>
  </div>
</template>
<style scoped>
.fa-info-circle {
  font-size: 11px;
}
</style>

<script>
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import { useCountryStore } from '@/stores/country'
import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { useSpotPriceStore } from '@/stores/spotPrice'
import { useDevice } from '@/composables/device'
import { useLocalizedLuxon } from '@/composables/localizedLuxon'

import IndicatorIcon from '@/components/common/IndicatorIcon.vue'
import LoadableTable from '@/components/common/LoadableTable.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'

export default {
  components: {
    IndicatorIcon,
    UtilityIcon,
    LoadableTable,
  },
  props: ['icon', 'util', 'yearPrice', 'monthPrice', 'priceLoader', 'error'],
  data() {
    return {
      loading: false,
      searchParameters: {
        country: null,
        utilitytype: null,
      },
    }
  },
  setup() {
    const device = useDevice()
    const localizedLuxon = useLocalizedLuxon()
    const countryStore = useCountryStore()
    const mobileUtilityStore = useMobileUtilityStore()
    const spotPriceStore = useSpotPriceStore()

    return {
      device,
      localizedLuxon,
      countryStore,
      mobileUtilityStore,
      spotPriceStore,
      formatter,
    }
  },

  computed: {
    visible() {
      if (!this.device.isMobile()) {
        return true
      }

      if (this.$route.name !== 'Home') {
        return false
      }

      if (this.mobileUtilityStore.shortUtility !== this.util) {
        return false
      }

      return true
    },
    baseTooltip() {
      if (this.util === 'e') {
        return this.$t(
          `general.marketNames.e.${this.countryStore.country.code}.spot.base`,
        )
      }

      return this.$t(
        `general.marketNames.g.${this.countryStore.country.code}.spot`,
      )
    },
    peakToolTip() {
      if (this.util === 'e') {
        return this.$t(
          `general.marketNames.e.${this.countryStore.country.code}.spot.peak`,
        )
      }

      return this.$t(
        `general.marketNames.g.${this.countryStore.country.code}.spot`,
      )
    },
    yearData() {
      if (this.yearPrice && this.yearPrice[0]) {
        return this.yearPrice[0]
      }

      return null
    },
    monthData() {
      if (this.monthPrice && this.monthPrice[0]) {
        return this.monthPrice[0]
      }

      return null
    },
    loaderData() {
      if (this.yearPrice) {
        return false
      }

      return true
    },
    convertsPrice() {
      return this.countryStore.country.code === 'nl' && this.util === 'g'
    },
    utilityType() {
      return this.util === 'e' ? 'Electricity' : 'Natural Gas'
    },
    currentYear() {
      return DateTime.now().year
    },
    tableClass() {
      if (this.util === 'e') {
        return 'table table-striped eTable'
      }

      return 'table table-striped gTable'
    },
    averages() {
      return this.spotPriceStore.averages[this.utilityType]
    },
    lastKnownMonth() {
      if (!this.averages.months || this.averages.months.length < 1) {
        return null
      }

      return this.averages.months[this.averages.months.length - 1]
    },
    monthDataDate() {
      if (this.monthData !== null) {
        return this.localizedLuxon
          .fromFormat(this.monthData.date, 'yyyyLLdd')
          .toFormat('LLLL')
      }

      return ''
    },
    yearDataDate() {
      if (this.yearData !== null) {
        return DateTime.fromFormat(this.yearData.date, 'yyyyLLdd').toFormat(
          'dd-LL',
        )
      }

      return ''
    },
  },
}
</script>
