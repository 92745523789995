<template>
  <div>
    <loadable-table
      :loading="loading"
      :error="error"
      :expected-rows="device.isMobilePortrait() ? 12 : 3"
      :columns="13"
      :sticky-head="true"
    >
      <template #head>
        <thead>
          <tr v-if="device.isMobilePortrait()">
            <th>
              <span class="d-sm-none">{{ currentYear }}</span>
            </th>

            <!--Gas-->
            <th v-if="!hasPeakPrices && convertsPrice">
              {{ $t('component.spottable.basem3') }}
            </th>
            <!--NL-->
            <th v-else-if="!hasPeakPrices">
              {{ $t('component.spottable.basemw') }}
            </th>
            <!--Not NL-->
            <!--Electricity-->
            <th v-else>{{ $t('pages.table.base') }}</th>

            <template v-if="hasPeakPrices">
              <th>{{ $t('pages.table.peak') }}</th>
              <th>{{ $t('pages.table.offpeak') }}</th>
            </template>
          </tr>
          <tr v-else>
            <th>
              <span class="d-sm-none">{{ currentYear }}</span>
            </th>
            <th
              v-for="month in months"
              @click="$emit('setMonth', month)"
              :class="{ selected: currentMonth == month.key }"
            >
              {{ month.name }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-if="prices.length">
        <template v-if="device.isMobilePortrait()">
          <tr
            v-for="month in months"
            :key="month.key"
            @click="$emit('setMonth', month)"
            :class="{ selected: currentMonth == month.key }"
          >
            <td>
              {{ month.name }}
            </td>

            <td>
              <!--Gas-->
              <template v-if="!hasPeakPrices && keyedPrices[month.key]">
                {{
                  formatter.volume(keyedPrices[month.key].base, convertsPrice)
                }}
              </template>
              <!--Electricity-->
              <template v-else-if="hasPeakPrices && keyedPrices[month.key]">
                {{ formatter.decimal(keyedPrices[month.key].base) }}
              </template>
              <template v-else> - </template>
            </td>

            <template v-if="hasPeakPrices">
              <td>
                <template v-if="keyedPrices[month.key]">
                  {{ formatter.decimal(keyedPrices[month.key].peak) }}
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <template v-if="keyedPrices[month.key]">
                  {{ formatter.decimal(keyedPrices[month.key].offpeak) }}
                </template>
                <template v-else> - </template>
              </td>
            </template>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>
                {{
                  convertsPrice
                    ? formatter.volume(yearAverage.base)
                    : formatter.decimal(yearAverage.base)
                }}
              </b>
            </td>
            <template v-if="hasPeakPrices">
              <td>
                <b>
                  {{
                    convertsPrice
                      ? formatter.volume(yearAverage.peak)
                      : formatter.decimal(yearAverage.peak)
                  }}
                </b>
              </td>
              <td>
                <b>
                  {{
                    convertsPrice
                      ? formatter.volume(yearAverage.offpeak)
                      : formatter.decimal(yearAverage.offpeak)
                  }}
                </b>
              </td>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>
              <template v-if="convertsPrice">
                {{ $t('component.spottable.basem3') }}
              </template>
              <template v-else>
                {{ $t('component.spottable.basemw') }}
              </template>
            </td>

            <td
              v-for="month in months"
              @click="$emit('setMonth', month)"
              :class="{ selected: currentMonth == month.key }"
            >
              <template v-if="keyedPrices[month.key]">
                {{
                  convertsPrice
                    ? formatter.volume(keyedPrices[month.key].base)
                    : formatter.decimal(keyedPrices[month.key].base)
                }}
              </template>
              <template v-else> - </template>
            </td>
          </tr>
          <template v-if="hasPeakPrices">
            <tr>
              <td>{{ $t('pages.table.peak') }}</td>
              <td
                v-for="month in months"
                @click="$emit('setMonth', month)"
                :class="{ selected: currentMonth == month.key }"
              >
                <template v-if="keyedPrices[month.key]">
                  {{ formatter.decimal(keyedPrices[month.key].peak) }}
                </template>
                <template v-else> - </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('pages.table.offpeak') }}</td>
              <td
                v-for="month in months"
                @click="$emit('setMonth', month)"
                :class="{ selected: currentMonth == month.key }"
              >
                <template v-if="keyedPrices[month.key]">
                  {{ formatter.decimal(keyedPrices[month.key].offpeak) }}
                </template>
                <template v-else> - </template>
              </td>
            </tr>
          </template>
        </template>
      </template>
      <tr v-else>
        <td class="selected text-center" colspan="13">
          {{ $t('pages.table.no results') }}
        </td>
      </tr>
    </loadable-table>

    <loadable-table
      v-if="device.isMobile() && !device.isMobilePortrait() && yearAverage"
      :loading="loading"
      :error="error"
      :columns="4"
      :sticky-head="true"
      :expected-rows="1"
    >
      <template #head>
        <thead>
          <tr>
            <th scope="col" style="width: 25%">
              <span class="d-sm-inline">
                {{ $t('general.year') }}
              </span>
            </th>

            <template v-if="convertsPrice">
              <th scope="col" style="width: 25%">
                <span class="d-sm-inline">
                  {{ $t('pages.table.basem3') }}
                </span>
              </th>
            </template>
            <template v-else>
              <th scope="col" style="width: 25%">
                <span class="d-sm-inline">
                  {{ $t('pages.table.basemw') }}
                </span>
              </th>
            </template>

            <template v-if="hasPeakPrices">
              <th scope="col" style="width: 25%">
                <span class="d-sm-inline">
                  {{ $t('pages.table.peak') }}
                </span>
              </th>
              <th scope="col" style="width: 25%">
                <span class="d-sm-inline">
                  {{ $t('pages.table.offpeak') }}
                </span>
              </th>
            </template>
          </tr>
        </thead>
      </template>

      <tr v-if="yearAverage.length === 0">
        <td class="selected text-center">
          {{ $t('pages.table.no results') }}
        </td>
      </tr>

      <tr>
        <td>{{ currentYear }}</td>
        <td>
          {{
            convertsPrice
              ? formatter.volume(yearAverage.base)
              : formatter.decimal(yearAverage.base)
          }}
        </td>
        <template v-if="hasPeakPrices">
          <td>
            {{
              convertsPrice
                ? formatter.volume(yearAverage.peak)
                : formatter.decimal(yearAverage.peak)
            }}
          </td>
          <td>
            {{
              convertsPrice
                ? formatter.volume(yearAverage.offpeak)
                : formatter.decimal(yearAverage.offpeak)
            }}
          </td>
        </template>
      </tr>
    </loadable-table>
  </div>
</template>

<script>
import { Info } from 'luxon'
import formatter from '@/formatter'

import { useDevice } from '@/composables/device'
import { useLocalizedLuxon } from '@/composables/localizedLuxon'

import LoadableTable from '@/components/common/LoadableTable.vue'

export default {
  components: {
    LoadableTable,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array,
      default: () => [],
    },
    currentDate: {
      type: String,
    },
    utilityType: {
      type: String,
      default: 'Electricity',
    },
    convertsPrice: {
      type: Boolean,
      default: false,
    },
    yearAverage: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const device = useDevice()
    const localizedLuxon = useLocalizedLuxon()

    return {
      device,
      formatter,
      localizedLuxon,
    }
  },
  computed: {
    hasPeakPrices() {
      return this.utilityType === 'Electricity'
    },
    currentMonth() {
      return this.currentDate.substring(4, 6)
    },
    currentYear() {
      return this.currentDate.substring(0, 4)
    },
    months() {
      return Info.months('short', {
        locale: this.localizedLuxon.getCurrentLocale(),
      }).map((month, index) => {
        return {
          value: index + 1,
          key: ('0' + (index + 1)).slice(-2),
          name: month,
        }
      })
    },
    keyedPrices() {
      return this.prices.reduce((months, item) => {
        let key = item.date.slice(4, 6)
        months[key] = item
        return months
      }, {})
    },
  },
}
</script>
