let appInsights = null

export const useAppInsights = () => {
  const setAppInsights = (instance) => {
    appInsights = instance
  }

  const trackEvent = (...args) => {
    if (appInsights !== null) {
      appInsights.trackEvent(...args)
    }
  }

  return {
    setAppInsights,
    trackEvent,
  }
}
