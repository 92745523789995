<template>
  <div class="swiper">
    <swiper-container
      v-if="isMobile"
      :pagination="{ el: '.swiper-pagination' }"
      :modules="[Pagination]"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <slot :name="slide" />
      </swiper-slide>
    </swiper-container>
    <div
      v-if="isMobile"
      class="swiper-pagination"
      :class="customPaginationClass"
    />

    <div v-else v-for="slide in slides">
      <slot :name="slide" />
    </div>
  </div>
</template>

<script>
import { useDevice } from '../../composables/device'
import { Pagination } from 'swiper/modules'
import 'swiper/element/css/pagination'
import { register } from 'swiper/element'

export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
    customPaginationClass: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const { isMobile } = useDevice()

    return { isMobile, Pagination }
  },
}
</script>
