import { useLanguageStore } from '@/stores/language'
import { DateTime } from 'luxon'

export function useLocalizedLuxon() {
  const languageStore = useLanguageStore()

  const locales = {
    nl: 'nl-NL',
    de: 'de-DE',
    en: 'en-US',
    fr: 'fr-FR',
  }

  function getCurrentLocale() {
    const locale = locales[languageStore.language.code]

    if (typeof locale === 'undefined') {
      throw new Error('Locale is undefined.')
    }

    return locale
  }

  function fromISO(dateTime) {
    return DateTime.fromISO(dateTime).setLocale(getCurrentLocale())
  }

  function fromLuxon(luxonObject) {
    return luxonObject.setLocale(getCurrentLocale())
  }

  function fromFormat(dateTime, format) {
    return DateTime.fromFormat(dateTime, format).setLocale(getCurrentLocale())
  }

  function now() {
    return DateTime.now().setLocale(getCurrentLocale())
  }

  function fromISOToFullLocaleString(dateTime) {
    return fromISO(dateTime).toLocaleString({
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return {
    fromISO,
    fromFormat,
    fromLuxon,
    fromISOToFullLocaleString,
    now,
    getCurrentLocale,
  }
}
