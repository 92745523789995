// Initialize app
import { createApp } from 'vue'

const app = createApp({
  data() {
    return {
      appLoaded: false,
    }
  },
  mounted() {
    this.appLoaded = true
  },
})

// Swal styles
import 'sweetalert2/dist/sweetalert2.css'

// jQuery
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

// Bootstrap
import 'bootstrap'

// Global components
import CountrySelector from '@/components/general/CountrySelector.vue'
import PriceTypeSelector from '@/components/general/PriceTypeSelector.vue'
import HeaderLogo from '@/components/general/HeaderLogo.vue'
import HeaderMenu from '@/components/general/HeaderMenu.vue'

app.component('country-selector', CountrySelector)
app.component('price-type-selector', PriceTypeSelector)
app.component('header-logo', HeaderLogo)
app.component('header-menu', HeaderMenu)

// Highcharts
import exportingInit from 'highcharts/modules/exporting'
import Highcharts from 'highcharts/highcharts'
import HighchartsVue from 'highcharts-vue'
window.Highcharts = Highcharts
window.HighchartsVue = HighchartsVue.default

exportingInit(Highcharts)
app.use(HighchartsVue)

// Tooltips
import tooltipDirective from '@/tooltipDirective'
app.directive('tooltip', tooltipDirective)

// App insights
import { appInsights } from '@/appInsights'
app.use(appInsights, {
  instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_KEY,
})

// Pdf Export host
app.provide('pdf-export-host', import.meta.env.VITE_PDF_IMG_HOST)

// Longpress
import { vOnLongPress } from '@vueuse/components'
app.directive('on-long-press', vOnLongPress)

// Initialize Router
import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes'
const router = createRouter({
  history: createWebHistory('/app'),
  routes,
})
app.use(router)

// Initialize Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)

// Initialize AppInsights
app.use(appInsights, {
  instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_KEY,
})

// Initialize translations
import { i18nVue, trans } from 'laravel-vue-i18n'
app.use(i18nVue, {
  resolve: async (lang) => {
    const language = lang.replace(
      /(php[_-])*(?<lang>[a-z]{2})(\.json)*/,
      '$<lang>'
    )
    const languages = import.meta.glob('../../../lang/php_*.json')

    return await languages[`../../../lang/php_${language}.json`]()
  },
  fallbackMissingTranslations: true,
  fallbackLang: 'en',
  shared: true,
  onLoad: () => {
    Highcharts.setOptions({
      lang: {
        loading: trans('component.chart.loading'),
      },
    })

    app.mount('#app')
  },
})
