<template>
  <div>
    <router-link
      :to="{ name: 'AlertOverview' }"
      class="btn btn-default pt-2 px-1 d-none d-md-inline-block float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.alert.back-to-overview') }}
    </router-link>

    <form @submit.prevent="validateForm" ref="form">
      <swipe-component
        :slides="slides"
        custom-pagination-class="refresh-graph-pagination"
      >
        <template v-slot:step-1>
          <div class="row">
            <h3
              class="col-11 pt-1 mb-4 refresh-graph-title"
              :class="titleClass"
            >
              <utility-icon
                class="ml-1"
                :util="alert.utility_type.value"
              ></utility-icon>
              <span class="ml-2">{{ $t('pages.alert.refresh-graph') }}</span>
            </h3>
          </div>

          <p class="ml-3" v-if="device.isMobile()">
            {{ $t('pages.alert.edit-step-1') }}
          </p>

          <div class="form-group row" :class="mobileFieldClass">
            <label class="col-12 col-md-2 mt-0 col-form-label" for="country">
              {{ $t('pages.alert.country') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="country"
                label="label"
                trackBy="value"
                :multiple="false"
                :options="countryOptions"
                :group-select="false"
                group-label="name"
                group-values="countries"
                v-model="alert.country"
                @update:model-value="onCountryChanged"
              >
              </default-multiselect>
            </div>
          </div>

          <div class="form-group row" :class="mobileFieldClass">
            <label class="col-12 col-md-2 col-form-label" for="utility-type">
              {{ $t('pages.alert.utility-type') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="utility-type"
                label="label"
                trackBy="value"
                :multiple="false"
                :options="utilityTypeOptions"
                :group-select="false"
                group-label="name"
                group-values="utilityTypes"
                v-model="alert.utility_type"
                @update:model-value="onUtilityTypeChanged"
              >
              </default-multiselect>
            </div>
          </div>

          <div class="form-group row" :class="mobileFieldClass">
            <label class="col-12 col-md-2 col-form-label" for="market">
              {{ $t('pages.alert.market') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="market"
                label="label"
                trackBy="marketLabel"
                :options="marketOptions"
                :group-select="false"
                group-label="name"
                group-values="markets"
                v-model="alert.market"
                @update:model-value="onMarketUpdated"
              >
              </default-multiselect>
            </div>
          </div>

          <div
            v-if="!areaIsDisabled"
            class="form-group row"
            :class="mobileFieldClass"
          >
            <label class="col-12 col-md-2 mt-0 col-form-label" for="area">
              {{ $t('pages.alert.area') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="area"
                label="name"
                trackBy="value"
                :multiple="false"
                :options="areaOptions"
                :group-select="false"
                group-label="name"
                group-values="areas"
                v-model="alert.area"
                @update:model-value="onAreaChanged"
              >
              </default-multiselect>
            </div>
          </div>

          <div
            v-if="!productIsDisabled"
            class="form-group row"
            :class="mobileFieldClass"
          >
            <label class="col-12 col-md-2 col-form-label" for="product">
              {{ $t('pages.alert.product') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="product"
                label="stripname"
                trackBy="stripname"
                :multiple="false"
                :limit="2"
                :options="productOptions"
                :searchable="true"
                :group-select="false"
                group-label="name"
                group-values="products"
                v-model="alert.product"
                @update:model-value="updateSeries(false)"
              >
              </default-multiselect>
            </div>
          </div>

          <div
            v-if="!priceCategoryIsDisabled"
            class="form-group row"
            :class="mobileFieldClass"
          >
            <label class="col-12 col-md-2 col-form-label" for="priceCategory">
              {{ $t('pages.alert.price-category') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="priceCategory"
                label="label"
                trackBy="value"
                :multiple="false"
                :limit="2"
                :options="priceCategoryOptions"
                :group-select="false"
                group-label="name"
                group-values="categories"
                v-model="alert.price_category"
                @update:model-value="updateSeries(false)"
              >
              </default-multiselect>
            </div>
          </div>

          <div class="form-group row" :class="mobileFieldClass">
            <label class="col-12 col-md-2 col-form-label" for="priceType">
              {{ $t('pages.alert.price-type') }}
            </label>
            <div class="col-12 col-md-9">
              <default-multiselect
                id="priceType"
                label="label"
                trackBy="value"
                :multiple="false"
                :limit="2"
                :options="priceTypeOptions"
                :group-select="false"
                group-label="name"
                group-values="priceTypes"
                v-model="alert.price_type"
                @update:model-value="updateSeries(false)"
              >
              </default-multiselect>
            </div>
          </div>
        </template>

        <template v-slot:step-2>
          <div v-if="device.isMobile()" class="row">
            <h3
              class="col-11 pt-1 mb-4 refresh-graph-title"
              :class="titleClass"
            >
              <utility-icon
                class="ml-1"
                :util="alert.utility_type.value"
              ></utility-icon>
              <span class="ml-2">{{ $t('pages.alert.refresh-graph') }}</span>
            </h3>
          </div>

          <p class="ml-3" v-if="device.isMobile()">
            {{ $t('pages.alert.refresh-graph-mobile-message') }}
          </p>

          <div class="row">
            <highcharts
              class="chart col-12"
              :key="shouldRerender"
              :options="chartOptions"
              ref="graph"
            ></highcharts>
          </div>
          <div v-if="device.isMobile()" class="row">
            <div class="row form-group ml-2 mt-5">
              <div v-if="formValid" style="color: red">
                <i class="fas fa-exclamation-triangle"></i>
                <span class="ml-3">{{ $t('pages.alert.form-not-valid') }}</span>
              </div>
            </div>
            <div class="mobile-buttons">
              <button type="submit" class="btn">
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>

          <div v-if="!device.isMobile()" class="form-group row ml-2 mt-5">
            <button type="submit" class="btn btn-primary">
              {{ $t('pages.alert.refresh-graph') }}
            </button>
          </div>
        </template>
      </swipe-component>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import svgToDataURL from '@/svgToDataUrl'
import lodash from 'lodash'
import { trans } from 'laravel-vue-i18n'
import { DateTime } from 'luxon'

import chartDefaults from '@/chartDefaults'
import chartDefaultsMobile from '@/chartDefaultsMobile'

import { useDevice } from '@/composables/device'

import { useAlertStore } from '@/stores/alert'
import { useFuturePriceStore } from '@/stores/futurePrice'
import { useSpotPriceStore } from '@/stores/spotPrice'
import { useCountryStore } from '@/stores/country'
import { useMarketStore } from '@/stores/market'
import { useProductStore } from '@/stores/product'
import { useAreaStore } from '@/stores/area'

import DefaultMultiselect from '@/components/common/DefaultMultiselect.vue'
import SwipeComponent from '@/components/general/SwipeComponent.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'

export default {
  name: 'EditGraph',
  props: ['alert_id'],
  components: {
    SwipeComponent,
    DefaultMultiselect,
    UtilityIcon,
  },
  data() {
    return {
      alert: {
        market: {},
        product: {},
        country: {},
        utility_type: {},
        area: {},
        trigger: {},
        price: '',
        price_category: {},
        price_type: {},
        comment: '',
        image: '',
      },
      alertUpdated: false,
      shouldRerender: false,
      productIsDisabled: false,
      areaIsDisabled: true,
      priceCategoryIsDisabled: false,
      lineColor: '#e6194B',
      slides: ['step-1', 'step-2'],
      availableAreas: [],
      formValid: true,
    }
  },
  setup() {
    const alertStore = useAlertStore()
    const areaStore = useAreaStore()
    const countryStore = useCountryStore()
    const device = useDevice()
    const futurePriceStore = useFuturePriceStore()
    const marketStore = useMarketStore()
    const productStore = useProductStore()
    const spotPriceStore = useSpotPriceStore()

    return {
      alertStore,
      areaStore,
      countryStore,
      device,
      futurePriceStore,
      spotPriceStore,
      marketStore,
      productStore,
    }
  },
  async mounted() {
    await Promise.all([
      this.marketStore.fetchMarkets(),
      this.productStore.fetchProducts(),
    ])
    this.getAlertById()
      .then(() => this.fillFields())
      .then(() => this.updateSeries(true))
  },
  watch: {
    alert: {
      handler() {
        this.shouldRerender = !this.shouldRerender
      },
      deep: true,
    },
    'device.deviceType': () => {
      if (this.device.isMobile()) {
        this.$refs.graph.chart.setSize(null, null)

        return
      }

      this.$refs.graph.chart.setSize(null, chartDefaults.chart.height)
    },
  },
  methods: {
    getAlertById() {
      return new Promise((resolve, reject) => {
        const alert = this.alertStore.find(this.alert_id)
        this.alert = {
          alert_id: alert.alert_id,
          country: alert.country,
          utility_type: {
            label: this.$t(
              'general.utilitytype.' +
                (alert.utility_type === 'Electricity' ? 'e' : 'g'),
              {},
              'en'
            ),
            value: alert.utility_type === 'Electricity' ? 'e' : 'g',
          },
          market: {
            market: alert.market,
            marketname: alert.market_name,
            markettype: alert.market_type,
            country: alert.country,
          },
          area: alert.area,
          product: alert.product,
          trigger: {
            label: this.$t(
              'general.comparison.' + alert.trigger + '.text',
              {},
              'en'
            ),
            value: alert.trigger,
          },
          price: alert.price,
          price_category: {
            label: this.$t(
              'general.price-categories.' + alert.price_category,
              {},
              'en'
            ),
            value: alert.price_category,
          },
          price_type: {
            label: this.$t(
              'general.pricetypes.' + alert.price_type.toLowerCase(),
              {},
              'en'
            ),
            value: alert.price_type,
          },
          comment: alert.comment,
          is_disabled: alert.is_disabled,
        }
        resolve(this.alert)
      })
    },
    fillFields() {
      this.lineColor =
        this.alert.utility_type.value === 'e' ? '#e6194B' : '#4363d8'
      this.alert.country = this.countryOptions[0].countries.find((option) => {
        return option.value === this.alert.country
      })

      const marketIndex = this.getMarketGroupIndex(this.alert.market.markettype)
      const marketName = this.alert.market.marketname
      this.alert.market = this.marketOptions[marketIndex].markets.find(
        function (option) {
          return option.marketname === marketName
        }
      )
      this.setArea()
      this.productIsDisabled = this.alert.market.markettype === 'spot'
      this.areaIsDisabled = !(
        this.alert.utility_type.value === 'g' &&
        (this.alert.country.value === 'DE' || this.alert.country.value === 'AT')
      )
      this.priceCategoryIsDisabled = this.alert.utility_type.value === 'g'

      const stripName = this.alert.product
      if (!this.alert.product) {
        return []
      }

      const productIndex = this.getProductGroupIndex(this.alert.product)
      this.alert.product = this.productOptions[productIndex].products.find(
        function (option) {
          return option.stripname === stripName
        }
      )
    },
    getMarketGroupIndex(markettype) {
      if (markettype === 'future') {
        return 0
      } else if (markettype === 'spot') {
        return 1
      }
    },
    getProductGroupIndex(product) {
      return product.includes('Cal') ? 0 : 1
    },
    getCalendar() {
      // Only prices from the 12 hour calendar will be taken into account for Electricity markets.
      return this.alert.market.utilitytype === 'Natural Gas' ? -1 : 12
    },
    onCountryChanged() {
      this.preFillMarket()
      this.preFillProduct()
      this.setArea()
      this.updateSeries()
    },
    //  --- Event based methods ---
    onUtilityTypeChanged() {
      this.lineColor =
        this.alert.utility_type.value === 'e' ? '#e6194B' : '#4363d8'
      this.preFillMarket()
      this.preFillProduct()
      this.setArea()
      this.resetPriceCategory()
      this.updateSeries()
    },
    setArea() {
      this.areaIsDisabled = !this.areaShown
      this.alert.area = this.areaStore.areas.filter(
        (area) => area.value === this.alert.area
      )[0]
    },
    resetPriceCategory() {
      this.priceCategoryIsDisabled = !this.priceCategoryShown
      this.alert.price_category = {
        label: this.$t('general.price-categories.base', {}, 'en'),
        value: 'base',
      }
    },
    onMarketUpdated() {
      if (this.alert.market.markettype === 'spot') {
        this.alert.product = null
        this.productIsDisabled = true
      } else {
        this.productIsDisabled = false
        this.preFillProduct()
      }
      this.updateSeries()
    },
    onAreaChanged() {
      this.updateSeries()
    },
    preFillMarket() {
      const market = this.markets.filter((market) => {
        return (
          this.alert.country.value === market.country &&
          market.markettype === 'future'
        )
      })[0]
      this.alert.market = market !== null ? market : this.markets[0]
      this.productIsDisabled = this.alert.market.markettype === 'spot'
    },
    preFillProduct() {
      const nextYear = DateTime.now().plus({ years: 1 }).year
      const product = this.products.filter((product) => {
        const filterByYear = nextYear === DateTime.fromISO(product.datekey).year
        return (
          filterByYear &&
          product.period === 'Y' &&
          this.alert.country.value === product.country &&
          product.markettype === this.alert.market.markettype
        )
      })[0]
      this.alert.product = product !== null ? product : this.products[0]
    },
    //  --- HighCharts methods ---
    setPriceLabel(chart, price, x, y) {
      chart.priceLabel = chart.renderer
        .label('Price: ' + price, x, y)
        .attr({
          fill: Highcharts.getOptions().colors[0],
          padding: 10,
          r: 5,
          zIndex: 8,
        })
        .css({
          color: '#FFFFFF',
        })
        .add()
    },
    setThresholdLine(chart, price) {
      const y = chart.yAxis[0].toPixels(price)
      // M: start point coordinates, L: coordinates of next points in tuples 10,10,10,5 => [x:10,y:10] and [x:10,y:5]
      const d = ['M', 0, y, 'L', chart.plotWidth + chart.plotLeft, y]
      chart.priceLine = chart.renderer
        .path(d)
        .attr({
          'stroke-width': 2,
          stroke: 'black',
        })
        .add()
    },
    setAlertThumbnail(chart) {
      return this.generateChartThumbnail(chart).then((data) => {
        this.alert.image = data
      })
    },
    generateChartThumbnail(chart) {
      chart.options.title.text = ''
      const svgChart = chart.getSVG()
      const dataUrl = svgToDataURL(svgChart)

      return fetch(dataUrl).then(function (res) {
        return res.blob().then(function (blob) {
          const file = new File([blob], 'Chart thumbnail', {
            type: 'image/svg+xml',
          })
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
          })
        })
      })
    },
    priceSeriesActionString() {
      return this.alert.market.markettype === 'future'
        ? 'futurePrice/indexGraph'
        : 'spotPrice/dayPrices'
    },
    priceSeriesGetterString() {
      return this.alert.market.markettype === 'future'
        ? 'futurePrice/priceGraph'
        : 'spotPrice/dayPrices'
    },
    getPayloadByAction(action) {
      const commonPayload = {
        utilitytype: this.alert.market.utilitytype,
        country: this.alert.country.value,
        market: this.alert.market.market,
        calendar: this.getCalendar(),
        area: this.alert.area.value,
      }

      if (action === 'futurePrice/indexGraph') {
        return {
          // We need 2 years of prices.
          ...commonPayload,
          start: DateTime.now().minus({ years: 2 }).toFormat('yyyyLLdd'),
          end: DateTime.now().endOf('day').toFormat('yyyyLLdd'),
          stripname: [this.alert.product.stripname],
          pricetype: this.alert.price_type.value,
          order: [
            'date:asc', // important otherwise the line will go randomly from point to point
          ],
        }
      } else if (action === 'spotPrice/dayPrices') {
        return {
          // We need one year of daily average prices.
          ...commonPayload,
          start: DateTime.now()
            .minus({ years: 1 })
            .startOf('day')
            .toFormat('yyyyLLdd'),
          end: DateTime.now().endOf('day').toFormat('yyyyLLdd'),
          period: 'D',
          order: [
            'date:asc', // important otherwise the line will go randomly from point to point
          ],
        }
      } else {
        return {}
      }
    },
    updateSeries: lodash.debounce(
      function (initialUpdate = false) {
        if (this.parametersAreSet) {
          this.error = false
          this.$refs.graph.chart.showLoading()

          const payload = this.getPayloadByAction(
            this.priceSeriesActionString()
          )
          if (this.alert.market.markettype === 'future') {
            this.futurePriceStore
              .fetchGraphPrices(payload)
              .catch((e) => {
                this.error = true
              })
              .finally(() => {
                if (this.$refs.graph) {
                  this.$refs.graph.chart.hideLoading()
                }
              })
          } else {
            this.spotPriceStore
              .fetchDayPrices(payload)
              .catch((e) => {
                this.error = true
              })
              .finally(() => {
                if (this.$refs.graph) {
                  this.$refs.graph.chart.hideLoading()
                }
                if (!initialUpdate) {
                  this.alertUpdated = true
                }
              })
          }
        }
      },
      500,
      {
        trailing: true,
      }
    ),
    getSeriesByAction(action, seriesFromStore, priceCategory) {
      if (action === 'futurePrice/indexGraph') {
        return seriesFromStore.reduce((series, entry) => {
          const name = entry.stripname
          const price = parseFloat(entry[priceCategory])
          if (!series[name]) {
            series[name] = {
              name: name,
              data: [],
              color: this.lineColor,
            }
          }
          series[name].data.push({
            y: price,
            x: DateTime.fromISO(entry.date).valueOf(),
            printInfo: {
              country: this.alert.country.value,
            },
          })
          return series
        }, [])
      } else if (action === 'spotPrice/dayPrices') {
        return seriesFromStore.reduce((series, entry) => {
          const name = entry.marketcode + ' ' + priceCategory
          const price = parseFloat(entry[priceCategory])
          if (!series[name]) {
            series[name] = {
              name: name,
              data: [],
              color: this.lineColor,
            }
          }
          series[name].data.push({
            y: price,
            x: DateTime.fromISO(entry.date).valueOf(),
            printInfo: {
              country: this.alert.country.value,
            },
          })
          return series
        }, [])
      } else {
        return {}
      }
    },

    //  --- Submitting methods ---
    postData() {
      this.alertStore
        .updateAlert(this.alert_id, {
          ...this.alert,
          alert_updated: this.alertUpdated,
          created_at: DateTime.fromISO(this.alert.created_at).toISO(),
        })
        .then(() => {
          Swal.fire(
            this.$t('general.update_success', { item: 'Graph' }, 'en'),
            '',
            'success'
          ).then(() => {
            window.location.href = '/app/alert'
          })
        })
        .catch((error) => {
          if (error.messages) {
            Swal.fire(
              this.$t('general.error_msg', {}, 'en'),
              error.messages.join('\n'),
              'error'
            )
          } else {
            console.error(error)
          }
        })
    },
    validateForm() {
      this.formValid = this.$refs.form.checkValidity()
      if (this.formValid) {
        this.setAlertThumbnail(this.$refs.graph.chart).then(() => {
          this.postData()
        })
      }
    },
  },
  computed: {
    mobileBottomMargin() {
      return this.device.isMobile() ? '' : 'mb-4'
    },
    mobileFieldClass() {
      return this.device.isMobile() ? 'col-sm-11 ml-sm-2 mt-0 pt-0 mb-0' : ''
    },
    titleClass() {
      return this.device.isMobile() ? 'ml-3' : ''
    },
    optionsMobile() {
      if (this.device.isMobile()) {
        return chartDefaultsMobile
      }

      return chartDefaults
    },
    areaShown() {
      return (
        this.alert.utility_type.value === 'g' &&
        (this.alert.country.value === 'DE' || this.alert.country.value === 'AT')
      )
    },
    priceCategoryShown() {
      return this.alert.utility_type.value !== 'g'
    },
    // ---- Highcharts Computed properties
    chartOptions() {
      const __chart = this
      return {
        ...chartDefaults,
        colors: chartDefaults.utilityColors[this.alert.market.utilitytype],
        chart: {
          ...chartDefaults.chart,
          height: this.device.isMobile() ? null : chartDefaults.chart.height,
          events: {
            render: function (event) {
              if (event.target.priceLine && event.target.priceLabel) {
                if (event.target.priceLine) {
                  event.target.priceLine.destroy()
                }
                if (event.target.priceLabel) {
                  event.target.priceLabel.destroy()
                }
              }

              this.setPriceLabel(
                event.target,
                Highcharts.numberFormat(this.alert.price, 1),
                event.target.xAxis[0].width -
                  (this.device.isMobile() ? 40 : 100),
                event.target.yAxis[0].toPixels(this.alert.price)
              )
              this.setThresholdLine(event.target, this.alert.price)
            }.bind(this),
          },
        },
        series: this.series,
        xAxis: {
          ...this.optionsMobile.xAxis,
        },
        yAxis: {
          crosshair: true,
          title: {
            text: this.yAxisLabel,
            offset: 0,
            x: this.device.isMobile() ? -30 : -37,
          },
          labels: {
            format: '{value}',
            style: {
              fontSize: '14px',
            },
            x: -5,
          },
        },
        legend: {
          ...this.optionsMobile.legend,
        },
        tooltip: {
          formatter() {
            return `<b>${this.series.name}</b><br>
                              ${DateTime.fromMillis(this.x).toFormat(
                                'd MMM yyyy'
                              )}<br>
                              ${trans('component.chart.price')}: ${
              __chart.yAxisLabel
            } ${this.y.toFixed(2).replace('.', ',')}`
          },
        },
      }
    },
    yAxisLabel() {
      return '€/MWh'
    },
    parametersAreSet() {
      return this.alert.utility_type && this.alert.market
    },
    series() {
      const priceCategory = !this.alert.price_category.value
        ? 'base'
        : this.alert.price_category.value
      const seriesFromStore =
        this.alert.market.markettype === 'future'
          ? this.futurePriceStore.graphPrices
          : this.spotPriceStore.dayPrices
      const series = this.getSeriesByAction(
        this.priceSeriesActionString(),
        seriesFromStore,
        priceCategory
      )
      return Object.values(series)
    },
    utilityTypeOptions() {
      return [
        {
          name: this.$t('pages.alert.select-utility-type', {}, 'en'),
          utilityTypes: [
            {
              label: this.$t('general.utilitytype.e', {}, 'en'),
              value: 'e',
            },
            {
              label: this.$t('general.utilitytype.g', {}, 'en'),
              value: 'g',
            },
          ],
        },
      ]
    },
    countries() {
      return this.countryStore.countries
    },
    countryOptions() {
      if (!this.countries.length) {
        return []
      }
      return [
        {
          name: this.$t('pages.alert.select-country', {}, 'en'),
          countries: this.countries.map((country) => {
            country.label = this.$t(
              'general.countries.' + country.code,
              {},
              'en'
            )
            country.value = country.code.toUpperCase()
            return country
          }),
        },
      ]
    },
    markets() {
      const utilityType =
        this.alert.utility_type.value === 'e' ? 'Electricity' : 'Natural Gas'
      const country = this.alert.country.value
      return this.marketStore.markets.filter((market) => {
        return market.utilitytype === utilityType && market.country === country
      })
    },
    marketOptions() {
      if (!this.markets.length) {
        return []
      }
      return this.markets.reduce(
        (groups, market) => {
          if (market.markettype === 'future') {
            market.label = market.marketname
            market.marketLabel = market.country + '_' + market.marketname // for tracking a difference --> onMarketUpdated will ran therefore
            groups[0].markets.push(market)
          } else if (market.markettype === 'spot') {
            market.label = market.marketname
            market.marketLabel = market.country + '_' + market.marketname
            if (market.marketname !== 'Powernext')
              groups[1].markets.push(market)
          }
          return groups
        },
        [
          { name: this.$t('general.market.future', {}, 'en'), markets: [] },
          { name: this.$t('general.market.spot', {}, 'en'), markets: [] },
        ]
      )
    },
    areaOptions() {
      if (this.areaIsDisabled) {
        return []
      }
      return [
        {
          name: this.$t('pages.alert.select-area', {}, 'en'),
          areas: this.areaStore.areas.filter((area) =>
            area.countries.includes(this.alert.country.code)
          ),
        },
      ]
    },
    products() {
      const country = this.alert.country.value
      const products = this.productStore.products.filter(function (product) {
        return product.country === country
      })
      return products
    },
    productOptions() {
      if (
        this.productIsDisabled ||
        !this.products.length ||
        !this.markets.length
      )
        return []
      const minCal = parseInt(
        DateTime.now().startOf('year').toFormat('yyyyLLdd')
      )
      const maxCal = parseInt(
        DateTime.now().plus({ years: 3 }).toFormat('yyyyLLdd')
      )
      const minQ = parseInt(
        DateTime.now().minus({ quarter: 1 }).toFormat('yyyyLLdd')
      )
      const maxQ = parseInt(
        DateTime.now().plus({ quarter: 6 }).toFormat('yyyyLLdd')
      )

      return this.products
        .filter((product) => {
          return (
            product.markettype === 'future' &&
            (product.period === 'Q' || product.period === 'Y')
          )
        })
        .reduce(
          (groups, product) => {
            if (
              product.period === 'Y' &&
              product.datekey > minCal &&
              product.datekey < maxCal
            )
              groups[0].products.push(product)
            else if (
              product.period === 'Q' &&
              product.datekey > minQ &&
              product.datekey < maxQ
            )
              groups[1].products.push(product)
            else if (product.period === 'Y') groups[2].products.push(product)
            else if (product.period === 'Q') groups[3].products.push(product)
            else groups[4].products.push(product)

            return groups
          },
          [
            {
              name: this.$t('component.graphs.recent Cal'),
              products: [],
            },
            {
              name: this.$t('component.graphs.recent Q'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other-cal'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other-q'),
              products: [],
            },
            {
              name: this.$t('component.graphs.other'),
              products: [],
            },
          ]
        )
    },
    triggerOptions() {
      return [
        {
          name: this.$t('pages.alert.select-trigger', {}, 'en'),
          triggers: [
            {
              label: this.$t('general.comparison.greater-than.text', {}, 'en'),
              value: 'greater-than',
            },
            {
              label: this.$t('general.comparison.smaller-than.text', {}, 'en'),
              value: 'smaller-than',
            },
          ],
        },
      ]
    },
    priceCategoryOptions() {
      return [
        {
          name: this.$t('pages.alert.select-price-category', {}, 'en'),
          categories: [
            {
              label: this.$t('general.price-categories.base', {}, 'en'),
              value: 'base',
            },
            {
              label: this.$t('general.price-categories.peak', {}, 'en'),
              value: 'peak',
            },
            {
              label: this.$t('general.price-categories.offpeak', {}, 'en'),
              value: 'offpeak',
            },
          ],
        },
      ]
    },
    priceTypeOptions() {
      return [
        {
          name: this.$t('pages.alert.select-price-type', {}, 'en'),
          priceTypes: [
            {
              label: this.$t('general.pricetypes.settlement', {}, 'en'),
              value: 'Settlement',
            },
            {
              label: this.$t('general.pricetypes.ask', {}, 'en'),
              value: 'Ask',
            },
            {
              label: this.$t('general.pricetypes.bid', {}, 'en'),
              value: 'Bid',
            },
          ],
        },
      ]
    },
  },
}
</script>

<style scoped>
.refresh-graph-title {
  padding-left: 0;
  margin-top: 7px;
  font-family: myriad-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
}
.error-message {
  color: red;
}

.chart {
  left: 0;
  right: 0;
  margin-bottom: 30px;
  margin-top: 15px;
}

.mobile-buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
}
</style>
