<template>
  <div>
    <div
      :class="{ show: shown }"
      class="settings-modal-backdrop"
      @click="dismiss"
    ></div>

    <div class="settings-modal" :class="classObject">
      <div class="settings-content">
        <div class="settings-header">
          <h4>{{ title }}</h4>
        </div>
        <div class="settings-body">
          <slot></slot>
        </div>
        <div class="settings-footer">
          <button type="button" class="btn btn-outline" @click="dismiss">
            {{ $t('general.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDevice } from '@/composables/device'

export default {
  props: ['title'],
  data: function () {
    return {
      shown: false,
    }
  },
  setup() {
    const device = useDevice()

    return { device }
  },
  computed: {
    classObject: function () {
      return {
        show: this.shown,
        'overflow-auto': this.device.isMobile(),
      }
    },
  },
  methods: {
    show() {
      this.shown = true
      this.$emit('shown')
    },
    dismiss() {
      this.shown = false
      this.$emit('dismissed')
    },
  },
}
</script>
