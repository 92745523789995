<template>
  <div>
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-default d-none px-1 d-md-inline-block float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.table.backtooverview') }}
    </router-link>

    <div class="fixed-mobileheader">
      <div class="pricingTitle">
        <h3>
          <utility-icon :util="$route.params.util"></utility-icon>
          <span v-if="searchParameters.country">
            {{ $t('general.market.spot') }}
            {{ searchParameters.country.toUpperCase() }}
            {{ $t(`general.utilitytype.${$route.params.util}`) }}
          </span>
          <i
            class="fas fa-info-circle black"
            v-tooltip:top.html="tooltips.base"
          ></i>
        </h3>
      </div>

      <div class="graph-buttons">
        <button @click="openSettings" class="btn btn-ghost">
          <i class="fas fa-sliders-h" aria-hidden="true"></i>
        </button>
        <a
          v-if="
            (!device.isIOS() || !device.isPWA()) && userStore.isAuthenticated
          "
          @click="downloadPdf"
          class="btn btn-ghost"
          download
        >
          <i class="fas fa-download" aria-hidden="true"></i>
        </a>
      </div>
    </div>

    <settings-modal ref="settingsModal" :title="$t('general.filters')">
      <div class="row">
        <div class="col-md-3">
          <label for="year">{{ $t('pages.table.year') }}</label>
          <default-multiselect
            id="year"
            :searchable="false"
            :options="years"
            :dropdown-direction="dropdownDirection"
            :model-value="currentDate.toFormat('yyyy')"
            @update:model-value="(year) => setYear(year)"
          >
          </default-multiselect>
        </div>

        <div v-if="hasAreas" class="col-md-3">
          <label for="area">{{ $t('pages.graph.area') }}</label>

          <default-multiselect
            id="area"
            label="name"
            trackBy="value"
            :options="availableAreas"
            :dropdown-direction="dropdownDirection"
            v-model="searchParameters.area"
            @update:model-value="areaChanged"
          >
          </default-multiselect>
        </div>
      </div>
    </settings-modal>

    <a
      v-if="(!device.isIOS() || !device.isPWA()) && userStore.isAuthenticated"
      @click="downloadPdf"
      class="btn btn-ghost d-none d-md-inline-block float-right mb-3"
      download
    >
      {{ $t('pages.graph.download') }}
    </a>
    <div class="btn btn-ghost d-none d-md-inline-block float-right mb-3" v-else>
      &nbsp;
    </div>

    <span class="clearfix"></span>
    <div v-if="!device.isMobile()" class="row">
      <div class="col-md-6 d-md-block">
        <loadable-table
          :items="
            spotPriceStore.isLoading.yearPrices ? [] : spotPriceStore.yearPrices
          "
          :loading="spotPriceStore.isLoading.yearPrices"
          :error="spotPriceStore.hasError.yearPrices"
          :columns="4"
          :sticky-head="true"
          :expected-rows="1"
        >
          <template #head>
            <thead>
              <tr>
                <th scope="col" style="width: 25%">
                  <span class="d-sm-inline">
                    {{ $t('general.year') }}
                  </span>
                </th>

                <template v-if="convertsPrice">
                  <th scope="col" style="width: 25%">
                    <span class="d-sm-inline">
                      {{ $t('pages.table.basem3') }}
                    </span>
                  </th>
                </template>
                <template v-else>
                  <th scope="col" style="width: 25%">
                    <span class="d-sm-inline">
                      {{ $t('pages.table.basemw') }}
                    </span>
                  </th>
                </template>

                <template v-if="hasPeakPrices">
                  <th scope="col" style="width: 25%">
                    <span class="d-sm-inline">
                      {{ $t('pages.table.peak') }}
                    </span>
                  </th>
                  <th scope="col" style="width: 25%">
                    <span class="d-sm-inline">
                      {{ $t('pages.table.offpeak') }}
                    </span>
                  </th>
                </template>
              </tr>
            </thead>
          </template>

          <tr v-if="spotPriceStore.yearPrices.length === 0">
            <td class="selected text-center">
              {{ $t('pages.table.no results') }}
            </td>
          </tr>

          <template #row="{ item }">
            <td>{{ currentDate.toFormat('yyyy') }}</td>
            <td>
              {{
                convertsPrice
                  ? formatter.volume(item.base)
                  : formatter.decimal(item.base)
              }}
            </td>

            <template v-if="hasPeakPrices">
              <td>
                {{
                  convertsPrice
                    ? formatter.volume(item.peak)
                    : formatter.decimal(item.peak)
                }}
              </td>
              <td>
                {{
                  convertsPrice
                    ? formatter.volume(item.offpeak)
                    : formatter.decimal(item.offpeak)
                }}
              </td>
            </template>
          </template>
        </loadable-table>
      </div>
    </div>

    <div class="d-md-block" :class="{ 'd-none': visibleStep !== STEP_MONTHS }">
      <MonthTable
        :loading="spotPriceStore.isLoading.monthPrices"
        :error="spotPriceStore.hasError.monthPrices"
        :prices="spotPriceStore.monthPrices"
        :utility-type="searchParameters.utilitytype"
        :current-date="currentDateText"
        :converts-price="convertsPrice"
        :year-average="spotPriceStore.yearPrices[0]"
        @setMonth="setMonth"
      >
      </MonthTable>
    </div>
    <div class="row">
      <div
        class="col-md-6 d-md-block"
        :class="{
          'd-none': visibleStep !== STEP_DAYS,
          'mb-5': device.isMobile(),
        }"
      >
        <loadable-table
          :items="
            spotPriceStore.isLoading.dayPrices ? [] : spotPriceStore.dayPrices
          "
          :loading="spotPriceStore.isLoading.dayPrices"
          :error="spotPriceStore.hasError.dayPrices"
          :sticky-head="true"
          :expected-rows="expectedDays"
        >
          <template #head>
            <thead>
              <tr>
                <th scope="col">
                  <span class="d-none d-sm-inline">
                    {{ currentDate.toFormat('LLL') }}
                  </span>
                  <span class="d-sm-none">
                    {{ currentDate.toFormat('LLL yy') }}
                  </span>
                </th>
                <template v-if="convertsPrice">
                  <th scope="col">{{ $t('pages.table.basem3') }}</th>
                </template>
                <template v-else>
                  <th scope="col">{{ $t('pages.table.basemw') }}</th>
                </template>
                <template v-if="hasPeakPrices">
                  <th scope="col">{{ $t('pages.table.peak') }}</th>
                  <th scope="col">{{ $t('pages.table.offpeak') }}</th>
                </template>
              </tr>
            </thead>
          </template>

          <tr v-if="spotPriceStore.dayPrices.length === 0">
            <td
              class="selected text-center"
              :colspan="searchParameters.utilitytype === 'Electricity' ? 4 : 2"
            >
              {{ $t('pages.table.no results') }}
            </td>
          </tr>

          <template #row="{ item }">
            <td
              :class="{
                selected: currentDate.toFormat('yyyyLLdd') === item.date,
              }"
              @click="setDay(item.date)"
            >
              {{ DateTime.fromFormat(item.date, 'yyyyLLdd').toFormat('d') }}
            </td>
            <td
              :class="{
                selected: currentDate.toFormat('yyyyLLdd') === item.date,
              }"
              @click="setDay(item.date)"
            >
              {{
                convertsPrice
                  ? formatter.volume(item.base)
                  : formatter.decimal(item.base)
              }}
            </td>
            <template v-if="hasPeakPrices">
              <td
                :class="{
                  selected: currentDate.toFormat('yyyyLLdd') === item.date,
                }"
                @click="setDay(item.date)"
              >
                {{ formatter.decimal(item.peak) }}
              </td>
              <td
                :class="{
                  selected: currentDate.toFormat('yyyyLLdd') === item.date,
                }"
                @click="setDay(item.date)"
              >
                {{ formatter.decimal(item.offpeak) }}
              </td>
            </template>
          </template>
        </loadable-table>
      </div>

      <div
        class="col-md-6 d-md-block"
        :class="{
          'd-none': visibleStep !== STEP_HOURS,
          'mb-5': device.isMobile(),
        }"
        v-if="searchParameters.utilitytype === 'Electricity'"
      >
        <loadable-table
          :items="
            spotPriceStore.isLoading.hourPrices ? [] : spotPriceStore.hourPrices
          "
          :loading="spotPriceStore.isLoading.hourPrices"
          :error="spotPriceStore.hasError.hourPrices"
          :expected-rows="expectedHours"
          :sticky-head="true"
        >
          <template #head>
            <thead>
              <tr>
                <th scope="col">
                  <span class="d-none d-sm-inline">
                    {{
                      localizedLuxon.fromLuxon(currentDate).toFormat('d LLL')
                    }}
                  </span>
                  <span class="d-sm-none">
                    {{
                      localizedLuxon.fromLuxon(currentDate).toFormat('d LLL yy')
                    }}
                  </span>
                </th>

                <template v-if="hasPeakPrices">
                  <th scope="col">{{ $t('pages.table.peak') }}</th>
                  <th scope="col">{{ $t('pages.table.offpeak') }}</th>
                </template>
              </tr>
            </thead>
          </template>

          <tr v-if="spotPriceStore.hourPrices.length === 0">
            <td
              class="selected text-center"
              :colspan="searchParameters.utilitytype === 'Electricity' ? 4 : 2"
            >
              {{ $t('pages.table.no results') }}
            </td>
          </tr>

          <template #row="{ item }">
            <td
              @click="setHour(item.hourpoint)"
              :class="{ selected: currentDate.hour == item.hourpoint }"
            >
              <template v-if="item.hourpoint">
                {{ `${item.hourpoint}:00` }}
              </template>
            </td>

            <td
              @click="setHour(item.hourpoint)"
              :class="{ selected: currentDate.hour == item.hourpoint }"
            >
              <template v-if="item.peak">
                {{ formatter.decimal(item.peak) }}
              </template>
            </td>
            <td
              @click="setHour(item.hourpoint)"
              :class="{ selected: currentDate.hour == item.hourpoint }"
            >
              <template v-if="item.offpeak">
                {{ formatter.decimal(item.offpeak) }}
              </template>
            </td>
          </template>
        </loadable-table>
      </div>
    </div>
  </div>
</template>
<script>
import lodash from 'lodash'
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import { END_DATE_NCG_GPL_MARKETS } from '@/contstants'

import { useAppInsights } from '@/composables/appInsights'
import { useDevice } from '@/composables/device'

import { useCountryStore } from '@/stores/country'
import { useAreaStore } from '@/stores/area'
import { useMarketStore } from '@/stores/market'
import { useSpotPriceStore } from '@/stores/spotPrice'
import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { useLocalizedLuxon } from '@/composables/localizedLuxon'
import { useUserStore } from '@/stores/user'

import MonthTable from '@/components/MonthTable.vue'
import DefaultMultiselect from '@/components/common/DefaultMultiselect.vue'
import LoadableTable from '@/components/common/LoadableTable.vue'
import SettingsModal from '@/components/general/SettingsModal.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'

export default {
  components: {
    MonthTable,
    DefaultMultiselect,
    LoadableTable,
    SettingsModal,
    UtilityIcon,
  },
  setup() {
    const appInsights = useAppInsights()
    const device = useDevice()
    const areaStore = useAreaStore()
    const countryStore = useCountryStore()
    const localizedLuxon = useLocalizedLuxon()
    const marketStore = useMarketStore(false)
    const mobileUtilityStore = useMobileUtilityStore()
    const spotPriceStore = useSpotPriceStore()
    const userStore = useUserStore()

    return {
      appInsights,
      device,
      areaStore,
      countryStore,
      localizedLuxon,
      marketStore,
      mobileUtilityStore,
      spotPriceStore,
      userStore,
      DateTime,
      formatter,
    }
  },
  data() {
    return {
      STEP_MONTHS: 'months',
      STEP_DAYS: 'days',
      STEP_HOURS: 'hours',
      dropdownDirection: 'bottom',
      visibleStep: null,
      currentDate: DateTime.now().startOf('month'),
      currentDateText: DateTime.now().startOf('month').toFormat('yyyyLLdd'),
      availableAreas: [],
      searchParameters: {
        area: null,
        utilitytype: null,
        start: null,
        end: null,
        market: null,
        country: null,
        order: ['country:asc', 'date:asc'],
      },
    }
  },
  computed: {
    hasAreas() {
      return (
        (this.searchParameters.country === 'de' ||
          this.searchParameters.country === 'at') &&
        this.searchParameters.utilitytype === 'Natural Gas'
      )
    },
    hasPeakPrices() {
      return this.searchParameters.utilitytype === 'Electricity'
    },
    tooltips() {
      let util = this.searchParameters.utilitytype === 'Electricity' ? 'e' : 'g'
      return {
        base: this.$t(
          'general.marketNames.' +
            util +
            '.' +
            this.countryStore.country.code +
            ((util === 'g') ? '.spot' : '.spot.base')
        ),
        peak: this.$t(
          'general.marketNames.' +
            util +
            '.' +
            this.countryStore.country.code +
            '.spot.peak'
        ),
      }
    },
    years() {
      return lodash.range(
        DateTime.now().year,
        DateTime.now().minus({ years: 20 }).year
      )
    },
    market() {
      const countryCode = this.searchParameters.country
        ? this.searchParameters.country
        : this.countryStore.country.code

      return this.marketStore.spotMarkets.find((market) => {
        return (
          market.country.toLowerCase() === countryCode &&
          market.utilitytype === this.searchParameters.utilitytype
        )
      })
    },
    expectedDays() {
      if (
        this.currentDateText.substring(0, 6) ===
        DateTime.now().toFormat('yyyyLL')
      ) {
        return DateTime.now().day
      }

      return this.currentDate.daysInMonth
    },
    expectedHours() {
      if (this.currentDateText === DateTime.now().toFormat('yyyyLLdd')) {
        return DateTime.now().hour
      }

      return 24
    },
    convertsPrice() {
      return (
        this.searchParameters.country === 'nl' &&
        this.$route.params.util === 'g'
      )
    },
  },
  methods: {
    setStartingParameters() {
      if (this.$route.params.step) {
        this.visibleStep = this.$route.params.step
      } else {
        this.visibleStep = this.STEP_MONTHS
      }

      if (
        this.countryStore.country.code === 'de' ||
        this.countryStore.country.code === 'at'
      ) {
        this.setAreas()
      }

      this.searchParameters.utilitytype =
        this.$route.params.util === 'e' ? 'Electricity' : 'Natural Gas'
      this.searchParameters.area =
        this.countryStore.country.code === 'de' ||
        this.countryStore.country.code === 'at'
          ? this.availableAreas.find((area) => {
              return area.value === '3'
            })
          : { value: -1 }
      this.searchParameters.country = this.countryStore.country.code

      this.refreshData()
    },
    refreshData() {
      this.getYearPrice()
      this.getMonthPrices()
      this.getDayPrices()
      this.getHourPrices()
    },
    getYearPrice() {
      this.spotPriceStore.fetchYearPrices({
        ...this.searchParameters,
        year: this.currentDate.toFormat('yyyy'),
        market: this.market.market,
        area: this.hasAreas ? this.searchParameters.area.value : '-1',
      })
    },
    getMonthPrices() {
      this.spotPriceStore.fetchMonthPrices({
        ...this.searchParameters,
        year: this.currentDate.toFormat('yyyy'),
        market: this.market.market,
        area: this.hasAreas ? this.searchParameters.area.value : '-1',
      })
    },
    getDayPrices() {
      this.spotPriceStore.fetchDayPrices({
        ...this.searchParameters,
        start: this.currentDate.startOf('month').toFormat('yyyyLLdd'),
        end: this.currentDate.endOf('month').toFormat('yyyyLLdd'),
        market: this.market.market,
        area: this.hasAreas ? this.searchParameters.area.value : '-1',
      })

      this.getHourPrices()
    },
    getHourPrices() {
      this.spotPriceStore.fetchHourPrices({
        ...this.searchParameters,
        start: this.currentDate.startOf('day').toFormat('yyyyLLdd'),
        end: this.currentDate.endOf('day').toFormat('yyyyLLdd'),
        market: this.market.market,
        area: this.hasAreas ? this.searchParameters.area.value : '-1',
        order: ['hourpoint:asc'],
      })
    },
    setAreas() {
      this.availableAreas = this.areaStore.areas.filter((area) => {
        // Handle the following cases:
        // 1. For years before END_DATE_NCG_GPL_MARKETS date, dont show THE market.
        // 2. For END_DATE_NCG_GPL_MARKETS date show all.
        // 3. For year after END_DATE_NCG_GPL_MARKETS date show only THE, CEGH markets

        // Do not return area in case its not delivered in the country
        if (!area.countries.includes(this.countryStore.country.code)) {
          return false
        }

        if (
          this.currentDate.startOf('year') <
          END_DATE_NCG_GPL_MARKETS.startOf('year')
        ) {
          return area.name !== 'THE' // (1)
        }

        return this.currentDate.startOf('year').year ===
          END_DATE_NCG_GPL_MARKETS.year
          ? true
          : area.name === 'THE' || area.name === 'CEGH' // (2,3)
      })

      this.searchParameters.area = this.availableAreas.find((area) => { return area.isDefaultInCountries.includes(this.countryStore.country.code) })?.value
    },
    openSettings() {
      this.$refs.settingsModal.show()
    },
    setYear(year) {
      this.currentDate = this.currentDate.set({ year: year })
      this.currentDateText = this.currentDate.toFormat('yyyyLLdd')

      this.appInsights?.trackEvent(
        { name: 'SpotPricePage-YearChanged' },
        {
          year: year,
          fullDate: this.currentDateText,
        }
      )

      this.setAreas()
      this.getYearPrice()
      this.getMonthPrices()
      this.getDayPrices()
      this.getHourPrices()
    },
    setMonth(monthObject) {
      const month = monthObject.value

      this.currentDate = this.currentDate.set({ month: month })
      this.currentDateText = this.currentDate.toFormat('yyyyLLdd')

      this.appInsights?.trackEvent(
        { name: 'SpotPricePage-MonthChanged' },
        {
          month: monthObject.value,
          fullDate: this.currentDateText,
        }
      )

      this.getDayPrices()
      this.getHourPrices()
      this.nextStep()
    },
    setDay(dateString) {
      const day = DateTime.fromFormat(dateString, 'yyyyLLdd').day

      this.currentDate = this.currentDate.set({ day: day })
      this.currentDateText = dateString

      this.appInsights?.trackEvent(
        { name: 'SpotPricePage-DateChanged' },
        {
          date: day,
          fullDate: this.currentDateText,
        }
      )

      this.getHourPrices()

      if (this.searchParameters.utilitytype === 'Electricity') {
        this.nextStep()
      }
    },
    setHour(hour) {
      this.currentDate = this.currentDate.set({ hour: hour })
      this.$forceUpdate()
    },
    areaChanged() {
      this.appInsights?.trackEvent(
        { name: 'SpotPricePage-AreaChanged' },
        {
          area: this.searchParameters.area,
        }
      )
      this.refreshData()
    },
    calculateIcon(newVal, oldVal) {
      if (!newVal || !oldVal) {
        return ''
      }

      if (newVal === oldVal) {
        return 'fas fa-minus colored-blue'
      }

      if (newVal > oldVal) {
        return 'fas fa-chevron-up colored-red'
      }

      return 'fas fa-chevron-down colored-green'
    },
    onCountryChanged() {
      this.searchParameters.country = this.countryStore.country.code
      this.setAreas()
      this.searchParameters.area =
        this.searchParameters.country === 'de' ||
        this.searchParameters.country === 'at'
          ? this.availableAreas[0]
          : { value: -1 }
      this.refreshData()
    },
    onMobileUtilityChanged() {
      if (!this.device.isMobile()) {
        return
      }

      let nextRouteAreas =
        (this.searchParameters.country === 'de' ||
          this.searchParameters.country === 'at') &&
        this.mobileUtilityStore.utility === 'gas'

      this.$router.push({
        name: nextRouteAreas ? 'SpotPriceMatrixArea' : 'SpotPriceMatrix',
        params: {
          ...this.$route.params,
          util: this.mobileUtilityStore.shortUtility,
          area: nextRouteAreas ? this.availableAreas[0].value : -1,
        },
      })
    },
    nextStep() {
      if (this.device.isMobile()) {
        let step =
          this.visibleStep === this.STEP_MONTHS
            ? this.STEP_DAYS
            : this.STEP_HOURS
        let routeName = this.$route.name

        if (routeName.indexOf('Step') === -1) routeName = `${routeName}Step`

        this.$router.push({
          name: routeName,
          params: {
            ...this.$route.params,
            step,
          },
        })
      }
    },
    downloadPdf() {
      let params = {
        ...this.searchParameters,
        util: this.$route.params.util,
        date: this.currentDate.toFormat('yyyy-LL-dd HH:mm:ss'),
        market: this.market.market,
        area: this.hasAreas ? this.searchParameters.area.value : '-1',
      }

      this.appInsights?.trackEvent(
        { name: 'SpotPricePage-PdfDownloaded' },
        {
          ...params,
        }
      )
      this.spotPriceStore.downloadPdf(params)
    },
  },
  async mounted() {
    await this.marketStore.fetchMarkets()

    this.setStartingParameters()
  },
  watch: {
    $route(to) {
      this.setStartingParameters()
    },
    'mobileUtilityStore.utility': function () {
      this.onMobileUtilityChanged()
    },
    'countryStore.country': function () {
      if (this.countryStore.initialized) {
        this.onCountryChanged()
      }
    },
  },
}
</script>
