<template>
  <div class="countryList">
    <a
      href="#"
      v-on:click.prevent="setCountry('nl')"
      :class="{ inactive: isNotActive('nl') }"
    >
      <img src="/images/nl.png" />
    </a>
    <a
      href="#"
      v-on:click.prevent="setCountry('be')"
      :class="{ inactive: isNotActive('be') }"
    >
      <img src="/images/be.png" />
    </a>
    <a
      href="#"
      v-on:click.prevent="setCountry('de')"
      :class="{ inactive: isNotActive('de') }"
    >
      <img src="/images/de.png" />
    </a>
    <a
      href="#"
      v-on:click.prevent="setCountry('at')"
      :class="{ inactive: isNotActive('at') }"
    >
      <img src="/images/at.png" />
    </a>
  </div>
</template>

<script>
import { useCountryStore } from '@/stores/country'

export default {
  setup() {
    const countryStore = useCountryStore()

    return { countryStore }
  },
  methods: {
    isNotActive(code) {
      if (this.countryStore.country === null) {
        return true
      }

      return this.countryStore.country.code !== code
    },
    setCountry(code) {
      this.countryStore.setCountryByCode(code)
      this.$emit('change')
    },
  },
}
</script>
