<template>
  <i :class="icon"></i>
</template>

<script>
export default {
  props: {
    preset: {
      required: false,
    },
    new: {
      required: false,
    },
    old: {
      required: false,
    },
  },
  computed: {
    newVal() {
      if (typeof this.new === 'number') return this.new
      return parseFloat(this.new)
    },
    oldVal() {
      if (typeof this.old === 'number') return this.old
      return parseFloat(this.old)
    },
    icon() {
      if (this.preset) {
        if (this.preset === '') return 'fas fa-minus colored-blue'
        if (this.preset === 'up') return 'fas fa-chevron-up colored-red'
        return 'fas fa-chevron-down colored-green'
      } else {
        if (!this.newVal || !this.oldVal) return ''
        if (this.newVal === this.oldVal) return 'fas fa-minus colored-blue'
        if (this.newVal > this.oldVal) return 'fas fa-chevron-up colored-red'
        return 'fas fa-chevron-down colored-green'
      }
    },
  },
}
</script>
