import { defineStore } from 'pinia'
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'

export const useMobileUtilityStore = defineStore('mobile-utility', () => {
  const utility = ref('electricity')

  const route = useRoute()

  const shortUtility = computed(() => {
    switch (utility.value) {
      case 'electricity':
        return 'e'
      case 'gas':
        return 'g'
      default:
        return utility.value
    }
  })

  function mapShortToLongUtility(shortUtility) {
    switch (shortUtility) {
      case 'e':
        return 'electricity'
      case 'g':
        return 'gas'
      default:
        return shortUtility
    }
  }

  function onRouteChange(route) {
    if (route.name === 'FuelPriceGraph') {
      utility.value = 'fuel'

      return
    }

    if (route.params.util && route.params.util !== shortUtility.value) {
      utility.value = mapShortToLongUtility(route.params.util)
    }
  }

  watch(route, (value) => {
    onRouteChange(value)
  })

  onMounted(() => {
    onRouteChange(route)
  })

  return { utility, shortUtility }
})
