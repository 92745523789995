import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { trans } from 'laravel-vue-i18n'

export const useFuelTypeStore = defineStore('fuel-type', () => {
  const fuelTypes = ref([
    {
      key: 'oil',
      value: 'Crude Oil',
    },
    {
      key: 'coal',
      value: 'Coal',
    },
    {
      key: 'co2',
      value: 'C02 Emission',
    },
  ])

  const translatedFuelTypes = computed(() => {
    return fuelTypes.value.map((type) => {
      return {
        name: trans(`general.utilitytype.${type.key}`),
        value: type.value,
        key: type.key,
      }
    })
  })

  return { fuelTypes, translatedFuelTypes }
})
