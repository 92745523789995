import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { trans } from 'laravel-vue-i18n'

export const usePriceCategoryStore = defineStore('price-category', () => {
  const priceCategories = ref([
    {
      name: 'component.futuretable.base',
      value: 'base',
    },
    {
      name: 'component.futuretable.peak',
      value: 'peak',
    },
    {
      name: 'component.futuretable.offpeak',
      value: 'offpeak',
    },
  ])

  const translatedPriceCategories = computed(() => {
    return priceCategories.value.map((type) => {
      return {
        name: trans(type.name),
        value: type.value,
      }
    })
  })

  return { priceCategories, translatedPriceCategories }
})
