import App from '@/pages/App.vue'
import Home from '@/pages/Home.vue'
import Conditions from '@/pages/Conditions.vue'
import BlogDetail from '@/pages/BlogDetail.vue'
import Language from '@/pages/Language.vue'
import Logout from '@/pages/Logout.vue'
import FuelPriceGraph from '@/pages/FuelPriceGraph.vue'
import FuturePriceGraph from '@/pages/FuturePriceGraph.vue'
import FuturePriceMatrix from '@/pages/FuturePriceMatrix.vue'
import SpotPriceMatrix from '@/pages/SpotPriceMatrix.vue'
import AlertOverview from '@/pages/alert/Overview.vue'
import AlertCreate from '@/pages/alert/Create.vue'
import AlertEdit from '@/pages/alert/Edit.vue'
import EditGraph from '@/pages/alert/EditGraph.vue'

export default [
  {
    name: 'Conditions',
    path: '/terms-and-conditions',
    component: Conditions,
  },
  {
    name: 'Language',
    path: '/language/',
    component: Language,
  },
  {
    name: 'Logout',
    path: '/logout/',
    component: Logout,
  },
  {
    path: '/',
    component: App,
    children: [
      {
        name: 'Home',
        path: '/',
        component: Home,
      },
      {
        name: 'BlogDetail',
        path: '/blog/:id',
        component: BlogDetail,
      },
      {
        name: 'FuelPriceGraph',
        path: '/graph/fuel/:util/:product',
        component: FuelPriceGraph,
      },
      {
        name: 'FuturePriceGraph',
        path: '/graph/:util',
        component: FuturePriceGraph,
      },
      {
        name: 'FuturePriceGraphProduct',
        path: '/graph/:util/:product',
        component: FuturePriceGraph,
      },
      {
        name: 'FuturePriceGraphArea',
        path: '/graph/area/:util/:area/:product',
        component: FuturePriceGraph,
      },
      {
        name: 'SpotPriceMatrix',
        path: '/table/:util',
        component: SpotPriceMatrix,
      },
      {
        name: 'SpotPriceMatrixStep',
        path: '/table/:util/:step',
        component: SpotPriceMatrix,
      },
      {
        name: 'SpotPriceMatrixArea',
        path: '/table/area/:util/:area',
        component: SpotPriceMatrix,
      },
      {
        name: 'SpotPriceMatrixAreaStep',
        path: '/table/area/:util/:area/:step',
        component: SpotPriceMatrix,
      },
      {
        name: 'FuturePriceMatrix',
        path: '/matrix/:util',
        component: FuturePriceMatrix,
      },
      {
        name: 'FuturePriceMatrixArea',
        path: '/matrix/:util/:area',
        component: FuturePriceMatrix,
      },
      {
        name: 'AlertOverview',
        path: '/alert',
        component: AlertOverview,
      },
      {
        name: 'AlertCreate',
        path: '/alert/create',
        component: AlertCreate,
      },
      {
        name: 'AlertEdit',
        path: '/alert/:alert_id/edit',
        component: AlertEdit,
        props: true,
      },
      {
        name: 'EditGraph',
        path: '/alert/:alert_id/edit-graph',
        component: EditGraph,
        props: true,
      },
    ],
  },
]
