import { CONVERSION_RATE } from '@/contstants'

export const formatter = {
  name(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  decimal(number, replaceZeroWithDash = false) {
    if (parseFloat(number) === 0 && replaceZeroWithDash === true) {
      return '-'
    }

    return parseFloat(number).toFixed(2).replace('.', ',')
  },

  volume(number) {
    return parseFloat(number * CONVERSION_RATE)
      .toFixed(2)
      .replace('.', ',')
  },
}

export default formatter
