<template>
  <div>
    <div class="mt-6">
      <div class="row justify-content-center text-center">
        <div class="col-md-5 col-lg-4 col-6">
          <h3>{{ $t('pages.language.pagetitle') }}</h3>

          <select
            v-model="languageCode"
            class="form-control mb-2"
            @change="changeLanguage"
          >
            <option
              v-for="language in languageStore.languages"
              :value="language.code"
              :key="language.code"
            >
              {{ language.name }}
            </option>
          </select>

          <router-link :to="{ name: 'Home' }" class="btn btn-primary">
            {{ $t('general.cancel') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLanguageStore } from '@/stores/language'

export default {
  data() {
    return {
      languageCode: null,
    }
  },
  setup() {
    const languageStore = useLanguageStore()

    return { languageStore }
  },
  mounted() {
    this.languageCode = this.languageStore.language.code
  },
  methods: {
    changeLanguage() {
      window.location.href = `/lang/${this.languageCode}`
    },
  },
}
</script>
