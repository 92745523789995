<template>
  <div class="btn-group float-left muTitle d-none d-md-block">
    <button
      type="button"
      class="dropdown-toggle custom-dd"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <template v-if="userStore.isGuest">
        {{ $t('menu.menu') }}
      </template>
      <slot v-else></slot>
      <i class="fas mr-2 fa-caret-down ml-1"></i>
    </button>

    <div class="dropdown-menu">
      <a
        v-if="userStore.cmsEnabled"
        href="/admin/dashboard"
        class="dropdown-item"
        target="_blank"
      >
        <i class="fas fa-tachometer-alt" aria-hidden="true"></i>
        {{ $t('menu.todashboard') }}
      </a>

      <router-link
        v-if="userStore.alertsEnabled && userStore.isAuthenticated"
        :to="{ name: 'AlertOverview' }"
        class="dropdown-item"
      >
        <i class="fas fa-bell" aria-hidden="true"></i>
        {{ $t('menu.alerts') }}
      </router-link>

      <router-link :to="{ name: 'Language' }" class="dropdown-item">
        <i class="fas fa-language" aria-hidden="true"></i>
        {{ $t('menu.changelang') }}
      </router-link>

      <refresh-button class="dropdown-item"></refresh-button>

      <router-link :to="{ name: 'Conditions' }" class="dropdown-item">
        <i class="fas fa-file-contract" aria-hidden="true"></i>
        {{ $t('menu.conditions') }}
      </router-link>

      <a
        v-if="userStore.isGuest"
        @click.prevent="redirectToLogin"
        class="dropdown-item"
        href=""
      >
        <i class="fas fa-door-open" aria-hidden="true"></i>
        {{ $t('menu.login') }}
      </a>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import RefreshButton from '@/components/general/RefreshButton.vue'

export default {
  components: {
    RefreshButton,
  },
  setup() {
    const userStore = useUserStore()

    return {
      userStore,
    }
  },
  methods: {
    redirectToLogin() {
      localStorage.removeItem('lastCountry')
      window.location.href = '/app/login'
    },
  },
}
</script>

<style scoped lang="scss"></style>
