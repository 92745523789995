<template>
  <i :class="icon"></i>
</template>

<script>
export default {
  props: {
    util: {
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.util) {
        case 'e':
          return 'fas fa-bolt e'
        case 'g':
          return 'fas fa-fire g'
        case 'fuel':
          return 'fas fa-industry fuel'
        default:
          return ''
      }
    },
  },
}
</script>
