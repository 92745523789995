<template>
  <div>
    <settings-modal ref="settingsModal" class="d-md-none">
      <country-selector @change="closeSettings"></country-selector>
      <price-type-selector @change="closeSettings"></price-type-selector>
      <div class="clearfix"></div>

      <router-link
        v-if="alertsAvailable"
        :to="{ name: 'AlertOverview' }"
        class="d-block my-2"
      >
        {{ $t('menu.alerts') }}
      </router-link>

      <router-link class="d-block mt-2" :to="{ name: 'Language' }">
        {{ $t('menu.changelang') }}
      </router-link>

      <refresh-button
        @click="closeSettings"
        class="d-block my-2 btn btn-link p-0"
      >
      </refresh-button>

      <router-link :to="{ name: 'Conditions' }" class="d-block my-2">
        {{ $t('menu.conditions') }}
      </router-link>

      <a v-if="userStore.isGuest" @click="redirectToLogin" class="d-block my-2">
        {{ $t('menu.login') }}
      </a>
    </settings-modal>

    <offline-warning />

    <nav class="mobile-nav-bar">
      <ul>
        <li @click="goBack" v-on-long-press.prevent="goHome">
          <img
            v-if="$route.name === 'Home'"
            src="/images/Scholt beeldmerk wit.svg"
          />
          <i class="fa fa-arrow-circle-left" v-else aria-hidden="true"></i>
        </li>

        <li
          :class="{ active: mobileUtilityStore.utility === 'electricity' }"
          @click="mobileUtilityStore.utility = 'electricity'"
        >
          {{ $t('general.utilitytype.e') }}
        </li>
        <li
          :class="{ active: mobileUtilityStore.utility === 'gas' }"
          @click="mobileUtilityStore.utility = 'gas'"
        >
          {{ $t('general.utilitytype.g') }}
        </li>
        <li v-if="fuelDisabled" class="disabled">
          {{ $t('general.utilitytype.fuel') }}
        </li>
        <li
          v-else
          :class="{ active: mobileUtilityStore.utility === 'fuel' }"
          @click="mobileUtilityStore.utility = 'fuel'"
        >
          {{ $t('general.utilitytype.fuel') }}
        </li>

        <li @click="openSettings">
          <i class="fas fa-cog" aria-hidden="true"></i>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { useUserStore } from '@/stores/user'
import { useRoute, useRouter } from 'vue-router'

import SettingsModal from '@/components/general/SettingsModal.vue'
import OfflineWarning from '@/components/general/OfflineWarning.vue'
import RefreshButton from '@/components/general/RefreshButton.vue'

export default {
  components: {
    SettingsModal,
    OfflineWarning,
    RefreshButton,
  },
  props: {
    guest: {
      type: Boolean,
      default: true,
    },
    alertsOn: {
      default: '0',
    },
  },
  setup() {
    const mobileUtilityStore = useMobileUtilityStore()
    const userStore = useUserStore()
    const route = useRoute()
    const router = useRouter()

    return {
      mobileUtilityStore,
      userStore,
      route,
      router,
    }
  },
  computed: {
    fuelDisabled() {
      return this.$route.name.indexOf('Matrix') !== -1
    },
    alertsAvailable() {
      return this.alertsOn === '1'
    },
  },
  methods: {
    goBack() {
      if (this.route.name !== 'Home') {
        this.router.back()
      }
    },
    goHome() {
      this.router.push({ name: 'Home' })
    },
    openSettings() {
      if (!this.$refs.settingsModal) {
        return
      }

      this.$refs.settingsModal.show()
    },
    closeSettings() {
      if (!this.$refs.settingsModal) {
        return
      }

      this.$refs.settingsModal.dismiss()
    },
    redirectToLogin() {
      localStorage.removeItem('lastCountry')
      window.location.href = '/app/login'
    },
  },
  watch: {
    route(to) {
      this.closeSettings()
    },
  },
}
</script>
