<template>
  <div id="blog">
    <div class="title d-none d-md-block">
      <h3>
        <i class="fas fa-globe"></i>
        {{ $t('pages.index.marketdevelopment') }}
      </h3>
    </div>

    <div class="pricingTitle d-block d-md-none">
      <h3>
        <i class="fas fa-globe text-dark"></i
        ><span>{{ $t('pages.index.marketdevelopment') }}</span>
      </h3>
    </div>

    <div class="searchArea">
      <div class="input-group">
        <div class="input-group-prepend">
          <button
            v-if="blogStore.searchPhrase === ''"
            class="btn btn-outline-secondary"
            type="button"
            @click="blogStore.fetchPosts()"
          >
            <i class="fas fa-search"></i>
          </button>
          <button
            v-else
            class="btn btn-outline-secondary"
            type="button"
            @click="resetSearch()"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="blogStore.searchPhrase"
          :placeholder="$t('pages.blog.searchmessage')"
          @keyup.enter="blogStore.fetchPosts()"
        />
      </div>
    </div>

    <div
      class="blogPost"
      v-for="post in blogStore.translatedPosts"
      :key="post.blogkey"
    >
      <div class="blogPosition">
        <h6>
          <router-link
            :to="{ name: 'BlogDetail', params: { id: post.blogkey } }"
          >
            {{ post.name }}
          </router-link>
        </h6>
        <div v-html="stripTeaser(post.content)"></div>
        <span>{{ localizedLuxon.fromISO(post.created_at).toRelative() }}</span>
      </div>
    </div>

    <nav v-if="blogStore.pagination.last_page !== 1" class="mt-2">
      <ul class="pagination">
        <li
          class="page-item"
          :class="{ disabled: blogStore.pagination.prev_page_url === null }"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="paginateTo(blogStore.pagination.current_page - 1)"
          >
            {{ $t('general.prev') }}</a
          >
        </li>
        <li
          class="page-item"
          v-for="page in paginationNumbers"
          :class="{ active: page === blogStore.pagination.current_page }"
          :key="page"
        >
          <a class="page-link" href="#" @click.prevent="paginateTo(page)">{{
            page
          }}</a>
        </li>
        <li
          class="page-item"
          :class="{ disabled: blogStore.pagination.next_page_url === null }"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="paginateTo(blogStore.pagination.current_page + 1)"
          >
            {{ $t('general.next') }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useBlogStore } from '@/stores/blog'
import { useCountryStore } from '@/stores/country'
import { useLocalizedLuxon } from '@/composables/localizedLuxon'

export default {
  setup() {
    const blogStore = useBlogStore()
    const countryStore = useCountryStore()
    const localizedLuxon = useLocalizedLuxon()

    return {
      blogStore,
      countryStore,
      localizedLuxon,
    }
  },
  methods: {
    resetSearch() {
      this.blogStore.searchPhrase = ''
      this.blogStore.fetchPosts()
    },
    paginateTo(page) {
      this.blogStore.fetchPosts(page)
      window.scrollTo(0, 0)
    },
    stripTeaser(string) {
      const cleanString = string.replace(/<\/?(?!a)(?!p)(?!ul)(?!li)\w*\b[^>]*>/gi, '');
      const maxLength = 300;
      if (cleanString.length > maxLength) {
        return cleanString.substring(0, maxLength) + '...';
      }
      return cleanString;
    },
    onCountryChanged() {
      this.resetSearch()
    },
  },
  mounted() {
    this.blogStore.fetchPosts()
  },
  watch: {
    'countryStore.country': function () {
      if (this.countryStore.initialized) {
        this.onCountryChanged()
      }
    },
  },
  computed: {
    paginationNumbers() {
      let current = this.blogStore.pagination.current_page
      let last = this.blogStore.pagination.last_page
      let left = current
      let right = current + 1
      let range = []
      let pages = []

      for (let index = 1; index <= last; index++) {
        if (index === 1 || index === last || (index >= left && index < right)) {
          range.push(index)
        }
      }

      let previousIndex
      range.forEach((index) => {
        if (previousIndex) {
          if (index - previousIndex === 2) {
            pages.push(previousIndex + 1)
          } else if (index - previousIndex !== 1) {
            pages.push('...')
          }
        }

        pages.push(index)
        previousIndex = index
      })

      return pages
    },
  },
}
</script>
