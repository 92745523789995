<template>
  <div class="text-center">
    <router-link
      v-if="device.isDesktop()"
      :to="{ name: 'Home' }"
      class="navbar-brand"
    >
      <img :src="src" alt="Scholt Energy" />
    </router-link>
  </div>
</template>

<script>
import { useDevice } from '@/composables/device'

export default {
  props: ['src'],
  setup() {
    const device = useDevice()

    return { device }
  },
}
</script>
