<template>
  <input
    type="date"
    v-model="currentDate"
    required
    class="form-control"
    :name="id"
    :id="id"
    :min="formattedMin"
    :max="formattedMax"
    @update:model-value="onUpdate"
  />
</template>

<script>
import lodash from 'lodash'
import { DateTime } from 'luxon'

export default {
  data() {
    const formattedMin = this.min ? this.min.toFormat('yyyy-LL-dd') : null
    const formattedMax = this.max ? this.max.toFormat('yyyy-LL-dd') : null
    const currentDate = (this.modelValue ?? DateTime.now()).toFormat(
      'yyyy-LL-dd'
    )

    return {
      formattedMin,
      formattedMax,
      currentDate,
    }
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object, // Luxon DateTime
      required: false,
      default: () => null,
    },
    id: {
      type: String,
      required: false,
    },
    max: {
      type: Object, // Luxon DateTime
      required: false,
      default: () => null,
    },
    min: {
      type: Object, // Luxon DateTime
      required: false,
      default: () => null,
    },
  },
  methods: {
    onUpdate(newValue) {
      this.currentDate = newValue
      this.emitUpdate()
    },
    emitUpdate: lodash.debounce(function () {
      this.$emit(
        'update:modelValue',
        DateTime.fromFormat(this.currentDate, 'yyyy-LL-dd')
      )
    }, 300),
  },
}
</script>
