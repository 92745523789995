<template>
  <transition name="slide-out">
    <div v-if="device.isOffline()" class="offline-warning">
      {{ $t('general.offline') }}
    </div>
  </transition>
</template>

<script setup>
import { useDevice } from '@/composables/device'

const device = useDevice()
</script>
