<template>
  <div v-if="visible">
    <div class="pricingTitle">
      <h3>
        <utility-icon util="fuel"></utility-icon>
        {{ $t('general.markets.fuelprices') }}
      </h3>
    </div>

    <loadable-table
      :table-class="tableClass"
      :loading="priceLoader"
      :error="error"
      :expected-rows="3"
      :items="priceLoader ? [] : fuelData"
    >
      <template #head>
        <thead>
          <tr>
            <th scope="col">{{ $t('component.futuretable.product') }}</th>
            <th scope="col" colspan="2">
              {{ $t('component.futuretable.base') }}
            </th>
            <th scope="col" v-if="fuelData.oil" class="text-center">
              {{
                DateTime.fromFormat(fuelData.oil.date, 'yLLdd').toFormat(
                  'dd-LL'
                )
              }}
            </th>
          </tr>
        </thead>
      </template>

      <template #row="{ item, index }">
        <td>{{ $t(`general.utilitytype.${index}`) }}</td>
        <template v-if="item">
          <td>
            <template v-if="index === 'co2'">
              &euro;{{ formatter.decimal(item.base) }}
            </template>
            <template v-else>
              &dollar;{{ formatter.decimal(item.base) }}
            </template>
          </td>
          <td></td>
          <td class="text-center">
            <indicator-icon :preset="item.baseposition" />
            {{ formatter.decimal(item.basediff) }}
          </td>
          <td class="text-right">
            <router-link
              :to="{
                name: 'FuelPriceGraph',
                params: {
                  product: item.stripname,
                  util: index,
                },
              }"
            >
              <i class="fas fa-chart-line" aria-hidden="true"></i>
            </router-link>
          </td>
        </template>
        <td colspan="4" v-else></td>
      </template>
    </loadable-table>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import { useMobileUtilityStore } from '@/stores/mobileUtility'

import { useDevice } from '@/composables/device'

import IndicatorIcon from '@/components/common/IndicatorIcon.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'
import LoadableTable from '@/components/common/LoadableTable.vue'

export default {
  components: {
    IndicatorIcon,
    UtilityIcon,
    LoadableTable,
  },
  props: ['util', 'latestPrice', 'priceLoader', 'error'],
  data() {
    return {
      loading: true,
      prices: [],
      searchParameters: {
        country: 'eu',
        start: DateTime.now().minus({ weeks: 1 }).toFormat('yyyyLLdd'),
        end: DateTime.now().toFormat('yyyyLLdd'),
      },
      showArea: false,
    }
  },
  setup() {
    const device = useDevice()
    const mobileUtilityStore = useMobileUtilityStore()

    return { device, mobileUtilityStore, formatter, DateTime }
  },
  computed: {
    tableClass() {
      if (this.util === 'e') {
        return 'table table-striped eTable'
      }

      return 'table table-striped gTable'
    },
    visible() {
      if (!this.device.isMobile()) {
        return true
      }

      if (this.$route.name !== 'Home') {
        return false
      }

      if (this.mobileUtilityStore.utility !== 'fuel') {
        return false
      }

      return true
    },
    fuelData() {
      if (this.latestPrice) {
        return this.latestPrice.reduce(
          (prices, item) => {
            switch (item.utilitytype) {
              case 'Crude Oil':
                prices.oil = item
                break
              case 'Coal':
                prices.coal = item
                break
              case 'C02 Emission':
                prices.co2 = item
                break
            }
            return prices
          },
          {
            oil: null,
            coal: null,
            co2: null,
          }
        )
      }
      return []
    },
  },
}
</script>
