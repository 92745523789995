import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useExportTypeStore = defineStore('export-type', () => {
  const exportTypes = ref([
    {
      name: 'pdf',
      value: 'application/pdf',
    },
    {
      name: 'jpeg',
      value: 'image/jpeg',
    },
  ])

  return {
    exportTypes,
  }
})
