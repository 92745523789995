<template>
  <div class="selectBoxPrice" v-if="userStore.isAuthenticated">
    <h3>{{ $t('component.selectbox.pricetype') }}:</h3>

    <select
      class="form-control sizeSelectBox"
      v-model="selectedPriceType"
      @change="setPriceType()"
    >
      <option
        v-for="priceType in priceTypeStore.translatedPriceTypes"
        :key="priceType.value"
        :value="priceType.value"
      >
        {{ priceType.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { usePriceTypeStore } from '@/stores/priceType'
import { useUserStore } from '@/stores/user'

export default {
  data() {
    return {
      selectedPriceType: null,
    }
  },
  setup() {
    const priceTypeStore = usePriceTypeStore()
    const userStore = useUserStore()

    return {
      priceTypeStore,
      userStore,
    }
  },
  mounted() {
    this.selectedPriceType = this.priceTypeStore.priceType.value
  },
  methods: {
    setPriceType() {
      this.priceTypeStore.setPriceTypeByValue(this.selectedPriceType)
      this.$emit('change')
    },
  },
}
</script>
