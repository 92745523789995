<template>
  <multiselect
    :id="id"
    :label="label"
    :trackBy="trackBy"
    :options="options"
    :modelValue="selection"
    @update:model-value="(newValue) => $emit('update:modelValue', newValue)"
    v-model="selection"
    :multiple="multiple"
    :limit="2"
    :group-select="groupSelect"
    :group-label="groupLabel"
    :group-values="groupValues"
    :open-direction="dropdownDirection"
    :allowEmpty="false"
    :searchable="searchable"
    :placeholder="$t('component.dropdown.placeholder')"
    :limitText="(count) => $t('component.dropdown.limitText', { count })"
    :selectLabel="$t('component.dropdown.selectLabel')"
    :selectGroupLabel="$t('component.dropdown.selectGroupLabel')"
    :selectedLabel="$t('component.dropdown.selectedLabel')"
    :deselectLabel="multiple ? $t('component.dropdown.deselectLabel') : ''"
    :deselectGroupLabel="$t('component.dropdown.deselectGroupLabel')"
  >
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>
import lodash from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selection: [],
    }
  },
  props: {
    groupLabel: {
      required: false,
    },
    groupSelect: {
      required: false,
    },
    groupValues: {
      required: false,
    },
    id: {
      required: false,
    },
    label: {
      required: false,
    },
    limit: {
      required: false,
    },
    multiple: {
      required: false,
    },
    options: {
      required: true,
    },
    trackBy: {
      required: false,
    },
    dropdownDirection: {
      required: false,
    },
    searchable: {
      required: false,
      default: false,
    },
    modelValue: {},
  },
  mounted() {
    this.selection = this.modelValue
  },
  watch: {
    modelValue(newValue) {
      this.selection = lodash.clone(newValue)
    },
  },
}
</script>
