import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axios'

export const useAlertStore = defineStore('alert', () => {
  const alerts = ref([])

  const pagination = ref({
    current_page: 1,
  })

  function find(id) {
    return alerts.value.find((alert) => alert.alert_id == id)
  }

  async function fetchAlerts(parameters) {
    const response = await axios.get('/api/alert', {
      params: parameters,
    })

    alerts.value = response.data.data
    pagination.value = response.data.pagination

    return alerts.value
  }

  async function fetchAlert(id) {
    const response = await axios.get(`/api/alert/${id}`)

    const index = alerts.value.findIndex((alert) => alert.alert_id == id)

    if (index === -1) {
      alerts.value.push(response.data.data)
    } else {
      alerts.value[index] = response.data.data
    }

    return response.data.data
  }

  async function storeAlert(alert) {
    return axios.post('/api/alert', alert).catch((error) => {
      if (!error?.response?.data?.errors) {
        return
      }

      return Promise.reject({
        messages: Objectvalues(error.response.data.errors),
        error: error,
      })
    })
  }

  async function updateAlert(id, alert) {
    return axios.put(`/api/alert/${id}`, alert).catch((error) => {
      if (!error?.response?.data?.errors) {
        return
      }

      Promise.reject({
        messages: Object.values(error.response.data.errors),
        error: error,
      })
    })
  }

  async function deleteAlert(id) {
    axios
      .delete(`/api/alert/${id}`)
      .then(() => {
        const index = alerts.value.findIndex((alert) => alert.alert_id == id)

        if (index !== -1) {
          delete alerts.value[index]
        }
      })
      .catch((error) => {
        if (!error?.response?.data?.errors) {
          return
        }

        Promise.reject({
          messages: Object.values(error.response.data.errors),
          error: error,
        })
      })
  }

  return {
    alerts,
    fetchAlerts,
    fetchAlert,
    pagination,
    find,
    storeAlert,
    updateAlert,
    deleteAlert,
  }
})
