<template>
  <div class="mt-6">
    <div class="row">
      <div class="col-md-12 text-center">
        <h3>{{ $t('pages.logout.sure') }}</h3>

        <router-link :to="{ name: 'Home' }" class="btn btn-primary">{{
          $t('general.no')
        }}</router-link>
        <button type="button" class="btn btn-success" @click="redirectToLogout">
          {{ $t('general.yes') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToLogout() {
      localStorage.removeItem('lastCountry')
      window.location.href = '/logout'
    },
  },
}
</script>
