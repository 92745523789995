<template>
  <div>
    <blog-index v-if="isLocal ? userStore.isGuest : userStore.isAuthenticated"></blog-index>
  </div>
</template>

<script>
import { useDevice } from '@/composables/device'
import { useUserStore } from '@/stores/user'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import BlogIndex from '@/pages/BlogIndex.vue'

export default {
  computed: {
    isLocal() {
      return window.Laravel.environment === 'local';
    }
  },

  components: {
    BlogIndex,
  },
  setup() {
    const device = useDevice()
    const userStore = useUserStore()
    const router = useRouter()

    onMounted(async () => {
      // Fetch the current authenticated user
      try {
        const response = await axios.get('/api/user')
        userStore.setUser(response.data) // Store the user in the state
      } catch (error) {
        console.log('Not authenticated or error fetching user:', error)
      }

      // Check if user is not authenticated and not on a mobile device
      if (!userStore.isAuthenticated && !device.isMobile()) {
        router.push({
          name: 'FuturePriceGraph',
          params: {
            util: 'e',
          },
        })
      }
    })

    return {
      userStore,
    }
  },
}
</script>
