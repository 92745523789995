<template>
  <div class="container">
    <div class="my-4">
      <h1 class="h2">{{ $t('pages.conditions.pagetitle') }}</h1>
    </div>

    <div v-html="$t('pages.conditions.content')"></div>

    <div class="form-group mb-0">
      <router-link :to="{ name: 'Home' }" class="btn btn-secondary btn-lg">
        {{ $t('pages.conditions.backtooverview') }}
      </router-link>
      <p><br /><br /></p>
    </div>
  </div>
</template>
