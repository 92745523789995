import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { trans } from 'laravel-vue-i18n'

export const useUtilityTypeStore = defineStore('utility-type', () => {
  const utilityTypes = ref([
    {
      key: 'e',
      value: 'Electricity',
    },
    {
      key: 'g',
      value: 'Natural Gas',
    },
  ])

  const translatedUtilityTypes = computed(() => {
    return utilityTypes.value.map((type) => {
      return {
        name: trans(`general.utilitytype.${type.key}`),
        value: type.value,
        key: type.key,
      }
    })
  })

  return { utilityTypes, translatedUtilityTypes }
})
