import { DateTime } from 'luxon'

export default {}

export const CONVERSION_RATE = 0.97694

/**
 *
 * The date in which the gas market consolidated into one. NCG and GPL consolidated into Trading Hub Europe (THE)
 * in Germany and Austria. The constant is used in the following components: FuturePriceGraph and FuturePriceMatrix
 * Based on the dates in which the user searches prices (e.g. future graph) different area options are populated in
 * the Area select box (e.g. if start date is after consolidation date then only the THE market is shown etc.).
 *
 */
export const END_DATE_NCG_GPL_MARKETS = DateTime.fromObject({
  year: 2021,
  month: 9,
}).startOf('month')
